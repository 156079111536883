import { Fragment } from 'react';
import MenuItem from '../../../components/MenuItem';
import { bundleDisplayIdxComparer, isBundleInStock } from '../../../helpers/bundleHelpers';
import { ItemMenuHeader } from '../../../components/ItemMenuHeader';

const ItemMenu = ({
  categoryRefs,
  subCategoryHeaderRefs,
  subCategoryRefs,
  menuCategories,
  basket,
  navigateTo,
  addItem,
  removeItem,
  findItemQuantity,
  outOfStockItems
}) => {  
  return (
    <>
      {menuCategories?.map((mc, catIdx) => (
        <Fragment key={catIdx}>
          <div
            key={`${mc.name}.${catIdx}`}
            id={`category-${mc.name}`}
            ref={(cat) => {
              categoryRefs.current[catIdx] = cat;
            }}
            className='is-flex-wrap-wrap'
          >
            {mc.subCategories?.length > 0 && mc.subCategories.map((sc, subcatIdx) => (
              <Fragment key={`${mc.partitionKey}.subcategory${catIdx}.${subcatIdx}`}>
                <div
                  ref={(subCat) => {
                    if (!subCategoryRefs.current[catIdx]) {
                      subCategoryRefs.current[catIdx] = [];
                    }
                    subCategoryRefs.current[catIdx][subcatIdx] = subCat;
                  }}
                  style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <ItemMenuHeader
                    category={mc}
                    subcategory={sc}
                    categoryIdx={catIdx}
                    subcatIdx={subcatIdx}
                    subCategoryHeaderRefs={subCategoryHeaderRefs}
                    navigateTo={navigateTo}
                  />
                  <div data-testid={`sub-category-${mc.subCategories?.length === 1 && subcatIdx === 0 ? mc.name : sc.name}`} className='sub-category-item-wrapper'>
                    {(sc.bundlesToRender?.length === 0 && sc.itemsToRender?.length === 0) && (
                      <>
                        <div
                          key={`${catIdx}.${subcatIdx}`}
                          className='subcategory-no-items-card'
                        >
                          No results found. Please adjust your filters.
                        </div>
                      </>)}
                    {([...(sc.bundlesToRender ?? [])])
                      .sort(bundleDisplayIdxComparer)
                      .map((el, idx) => {
                        const inStock = isBundleInStock(el, outOfStockItems);
                        let itemBasketIndex = -1;
                        if (basket && basket.bundles) {
                          itemBasketIndex = basket.bundles.findIndex(bItem => bItem.name === el.name);
                        }
                        return (
                          <MenuItem
                            item={el}
                            basket={basket}
                            navigateTo={navigateTo}
                            bundleItem
                            menuItem
                            columnWidth={4}
                            key={el.name}
                            outOfStock={!inStock}
                            numberInBasket={itemBasketIndex === -1 ? 0 : findItemQuantity(el.name, true)}
                            idx={idx}
                          />
                        );
                      })
                    }
                    {([...sc.itemsToRender] ?? [])
                      .sort((a, b) => (a.outOfStock === b.outOfStock) ? 0 : (a.outOfStock ? 1 : -1))
                      .map((item, ind) => {
                        let itemBasketIndex = -1;
                        if (basket && basket.items) {
                          itemBasketIndex = basket.items.findIndex(bItem => bItem.id === item.id);
                        }
                        return (
                          <Fragment key={`${mc.partitionKey}.subcategory${catIdx}.${subcatIdx}.pizza-${item.id}`}>
                            <MenuItem
                              id={'pizza-' + ind}
                              index={ind}
                              catIndex={catIdx}
                              item={{ ...item, cost: item.displayPrice }}
                              outOfStock={item.outOfStock}
                              columnWidth={4}
                              menuItem={true}
                              numberInBasket={itemBasketIndex === -1 ? 0 : findItemQuantity(item.id)}
                              addItem={addItem}
                              removeItem={removeItem}
                              navigateTo={navigateTo}
                              dietaryOptions={item.dietPreferences}
                              isLast={ind === sc.subProducts.length - 1}
                              outOfStockItems={outOfStockItems}
                            />
                          </Fragment>
                        );
                      })
                    }
                  </div>
                </div >
              </Fragment >
            ))}
          </div>
        </Fragment>
      ))}
    </>
  );
};

export default ItemMenu;
