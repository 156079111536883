import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Cross from '../shared/Cross';
import { historyMW } from '../../helpers/routingHelpers';

const WelcomeToPEModal = ({ onClose, onAddItem, inApp }) => {
  const navigate = useNavigate();
  const { isDelivery } = useSelector(state => state.basket);


  const addTo = async () => {
    await onAddItem(10000012599, 26, 306);
    historyMW.push('/loyalty', isDelivery, navigate);
  };

  return (
    <div
      className={`filter-modal pb-5 ${inApp? '' : 'pt-4'}`}
      style={inApp ? { textAlign: 'center' } : {}}
    >
      {!inApp &&
        <Cross onClick={onClose} absolute rightPosition={7} topPosition={7} />
      }
      <div className={'content w100'}>

        <h2 style={{ marginTop: inApp? 32 : 60, marginBottom: 0, lineHeight: '90%', textTransform: inApp? 'uppercase':'' }} className="title is-4">Welcome to the</h2>
        <h2 style={{ marginTop: 0, lineHeight: '90%', textTransform: inApp? 'uppercase':''  }} className="title is-4">pizzaexpress club</h2>
        <p style={{ margin: '1.5rem 0.4rem', fontSize: '1rem' }}>We welcome you to PizzaExpress club. You have been rewarded with Free dough balls for joining today. Do you wish to add dough balls to your order today?</p>
        <div className="is-flex is-flex-direction-column is-align-items-center" style={{ gap: '0.5rem' }}>
          <button onClick={addTo} className="btn btn-primary" style={{fontSize: 16}}>Yes, add to order</button>
          <button onClick={onClose} className="btn btn-secondary" style={{fontSize: 16}}>No, save for later</button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeToPEModal;
