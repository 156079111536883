import React from 'react';
import plusIcon from './../img/icons/plus-outlined.svg';
import minusIcon from './../img/icons/minus-outlined.svg';
import { getCals, getDefaultPortion } from '../helpers/basketHelpers';
import { useConfig } from '../helpers/useConfig';
import DietaryIcons from './DietaryIcons';
import { useAppSelector } from '../store/storeTypes';

const UpsellModalCard = ({
  id,
  screenWidth,
  item,
  item: {
    name,
    imageUrl,
    description,
    displayPrice,
    dietPreferences },
  quantity,
  inApp,
  handleQuantityChange }) => {

  const defaultPortion = getDefaultPortion(item);
  const config = useConfig();
  const showCalories = useAppSelector(state => state.menu.showCalories);

  const figureStyle = { display: 'flex', justifyContent: 'center', height: 99 };
  const backgroundStyle = {
    backgroundImage: 'url(' + imageUrl + ')',
    position: 'absolute',
    width: '100%',
    height: '100%'
  };

  const cals = getCals(item, null, true);

  return (
    <>
      <div
        key={id}
        className={`upsell-modal-card m-0 h100`}
        style={{
          marginTop: screenWidth < 769 ? 0 : '',
          marginBottom: screenWidth < 769 ? 0 : '',
          flexGrow: inApp && 1,
          display: inApp && 'flex',
          flexDirection: 'column',
        }}
      >
        <div className="upsell-modal-card-content image-container">
          <figure className="image" style={figureStyle}>
            <img src={imageUrl} className="background-image" style={backgroundStyle}>
            </img>
          </figure>
          {quantity > 0 ?
            <>
              <div className='upsell-item-banner'></div>
              <div className='upsell-item-count'>
                <p>{quantity}</p>
              </div>
            </>
            : null}
        </div>
        <div className="upsell-modal-card-content info">
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: 8, flexGrow: 1 }}>
            <div style={{ boxSizing: 'content-box', padding: '0 12px' }}>
              <div>
                <div className={`card-title is-prewrap`}>
                  {name}
                </div>
                {
                  defaultPortion &&
                  <div className='is-prewrap' style={{ marginBottom: 4, textAlign: 'left' }}>{defaultPortion.name}</div>
                }
              </div>
            </div>
            <div className="is-flex is-flex-direction-column is-align-items-center">
              <div className="is-flex is-justify-content-space-between w100"
                style={{ alignItems: 'center', flexWrap: 'wrap', gap: '8px 20px', padding: '0 12px 12px 12px' }}
              >
                {showCalories && <p className="calories" >{cals}</p>}
                <DietaryIcons icons={dietPreferences} iconSize={16} style={{ marginTop: 0 }} />
              </div>
            </div>
          </div>
          <div className='upsell-modal-quantity-wrapper'>
            <div className='upsell-modal-quantity'>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <p className="price" style={{ fontFamily: "Domaine-display" }}>{displayPrice.toFixed(2)}</p>
              </div>
              <div style={{ display: 'flex', gap: 4 }}>

                <img src={minusIcon} className="icon change-quantity" onClick={() => handleQuantityChange(item, false)} style={{ opacity: quantity ? 1 : 0.3 }} data-testid='upsell-item-card-decrease' />
                <img src={plusIcon} className="icon change-quantity" onClick={() => handleQuantityChange(item, true)} data-testid='upsell-item-card-increase' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpsellModalCard;
