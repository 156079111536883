import React, { useEffect } from 'react';
import { useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';
import { useSafeState } from '../../../hooks/useIsMounted';
import Close from '../../../img/icons/closeNoRing.svg';
import { useSelector } from 'react-redux';
let timeout;

const getDistanceFromBottom = (ref) => {
  const elementRect = ref.getBoundingClientRect();
  const viewportHeight = window.innerHeight;
  const distanceFromBottom = viewportHeight - elementRect.top;
  return distanceFromBottom;
}

const Notification = ({ refs, notification, setNotification }) => {
  const [domNode, setDomNode] = useSafeState();
  const reset = () => {
    clearTimeout(timeout);
    setNotification(false);
  };

  useLayoutEffect(() => {
    setDomNode(document.getElementById('notificationContainer'));
  }, []);

  useEffect(() => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(reset, 2000);
  }, [notification]);

  if (domNode) {
    return createPortal(
      notification ?
        <div className="notification" style={{ bottom: refs.current.headerRef ? getDistanceFromBottom(refs.current.headerRef) + 34 : 'unset' }}>
          <div
            className='block'
            style={{
              zIndex: 1,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <p style={{ textAlign: 'center', paddingLeft: 16 }}>
              {`Item added to order`}
            </p>
            <img src={Close} onClick={reset} style={{ padding: 16 }} alt='Dismiss notification' />
          </div>
        </div> :
        <div></div>
      ,
      domNode
    );
  } else {
    return null;
  }


};

export default Notification;