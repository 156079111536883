import React from 'react';
// import LoyaltyTicketCardStyle from './LoyaltyTicketCardStyle';
import locked from '../../img/loyalty/lock.svg';
import { getRewardImage } from '../../helpers/loyaltyHelpers';
import LoyaltyText from './LoyaltyText';
import beer from './../../img/loyalty/card/beer.svg';
import cocktail from './../../img/loyalty/card/cocktail.svg';
import coffee from './../../img/loyalty/card/coffee.svg';
import cookbook from './../../img/loyalty/card/cookbook.svg';
import deliveryAndCollection from './../../img/loyalty/card/delivery & collection.svg';
import dolcetti from './../../img/loyalty/card/dolcetti.svg';
import doughballs from './../../img/loyalty/card/doughballs.svg';
import familyBundle from './../../img/loyalty/card/family bundle.svg';
import generic from './../../img/loyalty/card/generic.svg';
import hearts from './../../img/loyalty/card/hearts.svg';
import jazz from './../../img/loyalty/card/jazz.svg';
import kids1 from './../../img/loyalty/card/kids 1.svg';
import pizza1 from './../../img/loyalty/card/pizza 1.svg';
import pizza2 from './../../img/loyalty/card/pizza 2.svg';
import pizza3 from './../../img/loyalty/card/pizza 3.svg';
import pizza4 from './../../img/loyalty/card/pizza 4.svg';
import pizzaSlice1 from './../../img/loyalty/card/pizza slices 1.svg';
import pizzaSlice2 from './../../img/loyalty/card/pizza slices 2.svg';
import present from './../../img/loyalty/card/present.svg';
import side from './../../img/loyalty/card/side.svg';
import softDrink from './../../img/loyalty/card/soft drink.svg';
import special1 from './../../img/loyalty/card/special 1.svg';
import special2 from './../../img/loyalty/card/special 2.svg';
import special3 from './../../img/loyalty/card/special 3.svg';
import water from './../../img/loyalty/card/water.svg';
import wineGlass from './../../img/loyalty/card/wine glass.svg';
import wine from './../../img/loyalty/card/wine.svg';

const imageLookup = {
  ['beer'] : beer,
  ['cocktail'] : cocktail,
  ['coffee'] : coffee,
  ['cookbook'] : cookbook,
  ['delivery & collection'] : deliveryAndCollection,
  ['dolcetti'] : dolcetti,
  ['doughballs'] : doughballs,
  ['family bundle'] : familyBundle,
  ['generic'] : generic,
  ['hearts'] : hearts,
  ['jazz'] : jazz,
  ['kids 1'] : kids1,
  ['pizza 1'] : pizza1,
  ['pizza 2'] : pizza2,
  ['pizza 3'] : pizza3,
  ['pizza 4'] : pizza4,
  ['pizza slices 1'] : pizzaSlice1,
  ['pizza slices 2'] : pizzaSlice2,
  ['present'] : present,
  ['side'] : side,
  ['soft drink'] : softDrink,
  ['special 1'] : special1,
  ['special 2'] : special2,
  ['special 3'] : special3,
  ['water'] : water,
  ['wine glass'] : wineGlass,
  ['wine'] : wine
};

export const transformBackgroundColor = (colourOfTile) => {
  switch (colourOfTile?.toLowerCase()) {
    case 'yellow': return 'yellow';
    case 'pink': return 'pink';
    case 'green': return 'green';
    case 'white': return 'white';
    case 'yellow sheen': return 'yellow-sheen';
    case 'light yellow sheen': return 'yellow-light-sheen';
    case 'lightyellowsheen': return 'yellow-light-sheen';
    case 'yellowsheen': return 'yellow-sheen';
    default: return 'orange';
  }
};
export const getBackgroundColor = (colourOfTile) => {
  return getBackgroundFilter(transformBackgroundColor(colourOfTile));
};

export const getBackgroundFilter = (backgroundColour) => {
  const keyword = 'sheen';
  if (!!backgroundColour | backgroundColour !== undefined) {
    var background = backgroundColour;
    const backgroundFilter = backgroundColour.toLowerCase().indexOf(keyword) >= 0;
    if (backgroundFilter) {
      var bgStr = backgroundColour.toLowerCase();
      var endStringReplace = bgStr.substring(bgStr.indexOf(keyword) - 1).replace('-', bgStr.substring(0, bgStr.indexOf(keyword) - 1) + ' has-');
      background = endStringReplace;
    }
    return background;
  } else {
    return 'yellow';
  }
};

export const getCardDescription = (lock, reward) => {
  if (!lock && reward.rewardDetails.expiryDateToDisplay) {
    return `Expires ${formatDate(reward.validTo)}`;
  } else {
    return reward.rewardDetails.rewardCardDescription;
  }
};

export const formatDate = date => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const dateToConvert = new Date(date);

  const validTo = `${dateToConvert.getDate()} ${months[dateToConvert.getMonth()]} ${dateToConvert.getFullYear()}`;

  return validTo;
};

const RewardTicket = ({ reward, handleModal, designOfTile, isOffer }) => {
  const loyaltyRewardImage = getRewardImage(designOfTile);
  const heading = reward.rewardDetails.rewardTitle;
  const description = getCardDescription(reward.isLocked, reward);
  let rewardImage = null;
  try {
    rewardImage = imageLookup[loyaltyRewardImage.image];
  }
  catch {
    console.warn('Unable to load rewards image');
  }

  return (
    <div
      style={{ minWidth: '257px', width: '257px', height: '127px' }}
      className={'loyalty-ticket'}
      onClick={() => handleModal({ reward, loyaltyRewardImage: loyaltyRewardImage, isOffer })}
    >
      <div className={`loyalty-ticket-bg ${reward.disabled ? 'is-disabled' : ''} ${reward.isLocked ? 'is-locked' : ''} ${reward.rewardDetails.colourOfTilenbwConverted?.toLowerCase()}`}>
        <div
          className="loyalty-ticket-content"
          style={{ fontSize: 20, flexGrow: 1, maxHeight: '100%' }}
        >
          {heading && (
              <h4 className="no-mouse-event mb-0 domain-display"
                style={{
                  fontSize: 20,
                  maxHeight: 'calc(100% - 15px)',
                  whiteSpace: 'break-spaces',
                  wordBreak: 'break-word',
                  overflow: 'hidden',
                  width: '128px',
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical'
                }}>
                <LoyaltyText text={heading} />
              </h4>          
          )}
          {description && (
            <small className="copy no-mouse-event dm-sans" style={{ fontSize: 12 }}>
              {description}
            </small>
          )}
        </div>
        <img className={`loyalty-ticket-image ${reward.rewardDetails.nbwRewardTheme === 'White' ? 'invert' : ''}`} src={rewardImage} />
      </div>
      {reward.isLocked && <div className="is-locked-icon is-absolute zi-3">
        <div className="icon-wrapper is-justify-content-center is-align-items-center">
          <img className="" alt="locked" src={locked} style={{ width: 40, opacity: 1 }} />
        </div>
      </div>}
    </div>
  );
};

export default RewardTicket;
