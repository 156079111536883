import React, { useEffect, useState } from 'react';
import { useConfig } from '../helpers/useConfig';
import { getCals, getExtras } from '../helpers/basketHelpers';
import { useBreakPoint } from '../hooks/useBreakPoint';
import useWindowDimensions from '../hooks/useWindowDimensions';
import minus from '../img/icons/basket-card-minus.svg';
import plus from '../img/icons/basket-card-plus.svg';
import { useSelector } from 'react-redux';
import DietaryIcons from './DietaryIcons';
import { useAppSelector } from '../store/storeTypes';

const getBundleOptions = (bundle) => {
  const optionsArr = bundle.sections.reduce((acc, cur) => {
    const products = cur.products.map(p => ({
      name: p.product.subProducts?.[0]?.subProducts?.[0].name ? `${p.product.name} ${p.product.subProducts?.[0]?.subProducts?.[0].name}` : p.product.name,
      cost: p.product.upliftPrice ? p.product.upliftPrice : 0,
      quantity: p.product.quantity
    }));
    return acc.concat(products);
  }, []);
  return optionsArr;
};

export const BasketItem = (props) => {
  const { id, name, cost, calculatedCost, imageUrl, quantity, subProducts, customID, basePrice, dietPreferences } = props.item;
  const dimensions = useWindowDimensions();
  const config = useConfig();
  const [extras, setExtras] = useState();
  const baseSelections = useSelector(state => state.menu.baseSelections);
  const restaurantId = useSelector(state => state.session.restaurant.id);
  const [selectedBaseCost, setSelectedBaseCost] = useState(cost || basePrice);
  const showCalories = useAppSelector(state => state.menu.showCalories);
  const duoRomanaIds = useSelector(state => state.session.frontEndConfig?.duoRomanaIds);

  /* AB- This comment is true as at 29/9/2023 with the code and style as we have now
   * The breakpoint of 1026 is required for controlling the top margin of dietary icons in this card.
   * If other breakpoints are required do not alter this one but create new ones.
   */
  const mediumScreen = useBreakPoint(dimensions?.width ?? 0, 1026);

  const { addItem, removeItem, navigateTo, isBundle, hidePiccoloCalories } = props;

  useEffect(() => {
    if (subProducts && subProducts[0].subProducts.length > 1) {
      const matchingBaseSelection = baseSelections.find(b => b.product === id);
      if (matchingBaseSelection) {
        setSelectedBaseCost(subProducts[0].subProducts.find(s => s.id === matchingBaseSelection.base).cost);
      } else {
        setSelectedBaseCost(subProducts[0].subProducts[1].cost);
      }
    }
  }, [subProducts, baseSelections]);

  useEffect(() => {
    if (customID && !isBundle) {
      const newExtras = getExtras(props.item);
      setExtras({ base: newExtras[0]?.name?.split('-')[0], toppings: newExtras.slice(1) });
    } else if (isBundle) {
      const newExtras = getBundleOptions(props.item);
      setExtras({ items: newExtras });
    }
  }, [props.item]);

  let displayCost = calculatedCost || selectedBaseCost;
  if (!displayCost) displayCost = 0;

  let discountTotal = 0;
  const discounts = props?.discounts?.filter(d => d.id === id || d.id === customID) || [];
  discounts.forEach(discount => discountTotal += discount.amount);
  const displayCostAfterDiscount = displayCost - discountTotal;

  const getCalories = () => {
    if (props.hideCalories || hidePiccoloCalories || !showCalories) return null;
    return getCals(props.item, null);
  };
  
  return (
    <div className='new-basket-item-container'>
      <div className='image-container'>
        <img className='image threebyfour basket-item-image' src={imageUrl} alt="food item" />
      </div>
      <div className='info-container'>
        <p
          className="info-title"
        >
          {name}
        </p>
        <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
          {discounts?.length > 0 ? <div><small><strike>{config?.CURRENCY_SYMBOL}{displayCost.toFixed(2)}</strike></small>&nbsp;<span className="is-text-weight-semibold">{displayCostAfterDiscount !== 0 ? <>{config.CURRENCY_SYMBOL}{(displayCostAfterDiscount).toFixed(2)}</> : <>Free</>}</span></div>
            : <span className="total-price">{config?.CURRENCY_SYMBOL}{displayCost.toFixed(2)}</span>}
          {getCalories()}
          {extras ? <span className='pizza-base'>{extras.base}</span> : null}
        </div>
      </div>
      {
        extras && (extras.toppings?.length || extras.items?.length) ?
          <div className='extras-container'>
            <div className='is-flex is-flex-direction-column extras'>
              {extras.toppings?.map((el, idx) => <div key={idx} className='is-flex is-justify-content-space-between-mobile is-justify-content-flex-start-tablet basket-card-extra'><p>+ {el.name}</p><p className='price'>{config.CURRENCY_SYMBOL}{el.cost.toFixed(2)}</p></div>)}
              {extras.items?.map((el, idx) => <div key={idx} className='is-flex is-justify-content-space-between-mobile is-justify-content-flex-start-tablet basket-card-extra' ><p>{el.quantity} x {el.name}</p></div>)}
            </div>
          </div>
          : null
      }
      <div className='dietary-container'>
        {dietPreferences && !duoRomanaIds?.includes(id)
          && <DietaryIcons icons={dietPreferences} style={{ justifyContent: 'flex-end', marginTop: `${mediumScreen ? '2px' : '4px'}` }} />}
      </div>
      <div className='buttons-container'>
        {!props.outOfStock && (
          <div className="block is-flex is-justify-content-flex-end">
            <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
              {
                customID &&
                <a
                  className="link dm-sans"
                  style={{ paddingRight: '0.5rem' }}
                  onClick={() => navigateTo(`/${isBundle ? 'bundle' : 'customise'}/${isBundle ? name : id}?customId=${customID}`)}
                >Edit</a>
              }
              <img src={minus} className="icon is-medium" onClick={() => removeItem(props.item)} data-testid='decrease' />
              <p className='basket-card-quantity' data-testid='quantity'>{quantity}</p>
              <img src={plus} className="icon is-medium" onClick={() => addItem(props.item)} data-testid='increase' />
            </div>
          </div>)}
      </div>
    </div>
  );
};