import React, { useEffect, useState, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';
import useWindowDimensions from '../hooks/useWindowDimensions';

import chevronLeftBubble from '../img/icons/left-btn.svg';
import chevronRightBubble from '../img/icons/right-btn.svg';
import chevronLeftWhiteBubble from '../img/icons/ChevronLeftWhiteBubble.svg';
import chevronRightWhiteBubble from '../img/icons/ChevronRightWhiteBubble.svg';

import { useSelector } from 'react-redux';

export const CarouselItem = ({
  children,
  cardWidth,
  cardScreenDisplay,
  isOffsetMobile,
  mobileOffsetPadding,
  index,
  setActiveIndex,
  colourInverted,
  cardCount,
  indicator,
  cardDisplay
}) => {
  return (
    <div
      style={{ minWidth: (cardWidth - (isOffsetMobile ? mobileOffsetPadding : 0)) / cardScreenDisplay }}
      className={'carousel-item'}
    >
      <div
        style={{ marginRight: !isOffsetMobile ? 16 : 4, marginLeft: !isOffsetMobile ? 16 : 4}}
        className={'block'}
      >
        {
          children &&
          React.cloneElement(children, {
            index,
            setActiveIndex,
            colourInverted,
            cardCount,
            indicator,
            cardDisplay
          })
        }
      </div>
    </div>
  );
};

const Carousel = ({
  children,
  autoplay,
  allowReduceForSmallerScreens = false,
  fullWidth = true,
  hasOffsetMobile,
  title,
  centerButtons,
  colourInverted,
  showCounter,
  hideIndicators
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [carouselWidth, setCarouselWidth] = useState(0);
  const [isOffsetMobile, setOffsetMobile] = useState(false);
  const { inApp } = useSelector(state => state.session);
  
  if (autoplay === undefined) {
    autoplay = false;
  }
  const [paused, setPaused] = useState(autoplay);
  const screenDimensions = useWindowDimensions();
  let cardDisplay;

  if (screenDimensions.width <= 768) {
    cardDisplay = 1;
  } else if (screenDimensions.width <= 1215 && allowReduceForSmallerScreens) {
    cardDisplay = fullWidth ? 2 : 1;
  } else {
    cardDisplay = fullWidth ? 3 : 2;
  }

  const updateIndex = (newIndex) => {
    if (children.length > cardDisplay) {
      // Allow scrolling if there are cards that cannot be displayed
      if (newIndex < 0) {
        // Stop overscrolling when swiping left
        newIndex = activeIndex;
      } else if (newIndex >= React.Children.count(children)) {
        // Stop overscrolling when swiping right
        newIndex = activeIndex;
      } else if (children.length - cardDisplay < newIndex) {
        // Don't display a blank space when translating card position
        newIndex = activeIndex;
      }
      setActiveIndex(newIndex);
    }
  };

  const carousel = useRef();

  useEffect(() => {
    if (screenDimensions.width <= 768 && hasOffsetMobile === true) {
      setOffsetMobile(true);
    } else {
      setOffsetMobile(false);
    }
  }, [screenDimensions]);

  useEffect(() => {
    if (carousel.current) {
      setCarouselWidth(carousel.current.clientWidth);
    }
  }, [screenDimensions, carousel]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (paused) {
        updateIndex(activeIndex + 1);
      }
    }, 3000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1),
    preventScrollOnSwipe: false,
    trackMouse: true,
  });

  const indicator = children.length - cardDisplay + 1;
  const mobileOffsetPadding = 40;

  return (
    <div className="carousel-wrapper" ref={carousel} style={{ maxWidth: '100vw' }}>
      {
        (!inApp || title) &&
        <div className={`block is-flex ${centerButtons && !title ? 'is-justify-content-center' : 'is-justify-content-space-between'} is-align-items-center`} style={{ padding: '0 1rem', marginBottom: `${screenDimensions.width >= 378 ? '38px' : '31px'}` }}>
          {
            title &&
            <p className='room-for-more'>{title}</p>
          }
          {
            (!inApp || title) &&
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
              <img
                onClick={() => updateIndex(activeIndex - 1)}
                data-testid='loyalty-slider-left-btn'
                style={{ cursor: 'pointer', opacity: activeIndex === 0 ? 0.5 : 1 }}
                src={colourInverted ? chevronLeftWhiteBubble : chevronLeftBubble}
              />
              {
                showCounter &&
                <p className='dm-sans'>{activeIndex + 1}/{indicator === 0 ? 1 : indicator}</p>
              }
              <img
                onClick={() => updateIndex(activeIndex + 1)}
                data-testid='loyalty-slider-right-btn'
                src={colourInverted ? chevronRightWhiteBubble : chevronRightBubble}
                style={{ cursor: 'pointer', opacity: activeIndex === (children.length - cardDisplay) || children.length === 1 ? 0.5 : 1 }}
              />
            </div>
          }
        </div>
      }
      <div
        {...handlers}
        className="carousel is-12 w100"
        onMouseEnter={() => autoplay && setPaused(true)}
        onMouseLeave={() => autoplay && setPaused(false)}
      >
        <div
          className={'inner is-mobile'}
          style={{
            transform: `translateX(-${activeIndex * ((carouselWidth - (isOffsetMobile ? mobileOffsetPadding : 0)) / cardDisplay) + 'px'})`,
            display: 'flex',
            alignItems: 'stretch',
            paddingLeft: isOffsetMobile && mobileOffsetPadding / 2,
            paddingRight: isOffsetMobile && mobileOffsetPadding / 2,
          }}
        >
          {React.Children.map(children, (child, index) => {
            return React.cloneElement(child, {
              index,
              carouselLength: children.length,
              length: children.length,
              cardWidth: carouselWidth,
              cardScreenDisplay: cardDisplay,
              isOffsetMobile: isOffsetMobile,
              mobileOffsetPadding: mobileOffsetPadding,
              setActiveIndex: updateIndex,
              colourInverted,
              indicator,
              cardCount: children.length,
              cardDisplay
            });
          })}
        </div>
        {
          !hideIndicators &&
          <div className="indicators pt-5">
            {indicator > 1 &&
              Array.from(Array(indicator)).map((x, index) => (
                <div
                  key={index}
                  className={`indicator ${index === activeIndex ? 'active' : ''}`}
                  onClick={() => {
                    updateIndex(index);
                  }}
                ></div>
              ))}
          </div>
        }
      </div>
    </div>
  );
};

export default Carousel;
