import checkboxTicked from '../../img/icons/checked.svg';
import checkboxEmpty from '../../img/icons/checkboxempty.svg';
import { getPeppers } from '../../features/menu/components/CustomiseCard';
import React, { useState } from 'react';
import Cross from '../shared/Cross';
import { RequiredTag } from '../RequiredTag';

export const ChoiceModal = ({ state, onClose, inApp }) => {
  const [selectedId, setSelectedId] = useState();
  const [errorState, setErrorState] = useState(false);
  const handleAdd = () => {
    if (!selectedId) {
      setErrorState({ isErrored: true, hasInteracted: true });
      return;
    }
    state.resolve(selectedId);
    onClose();
  };

  const handleClose = () => {
    state.reject();
    onClose();
  }

  const handleSelected = (id) => {
    setSelectedId(id);
    setErrorState(es => ({ ...es, isErrored: false, hasInteracted: true }));
  };

  return (
    <div style={{
      width: '100%',
      textAlign: 'center',
      position: 'relative',
      padding: '80px 32px 56px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 32
    }}>
      {!inApp &&
        <Cross onClick={handleClose} absolute rightPosition={16} topPosition={16} />
      }
      <h1
        className="title is-spaced is-uppercase"
        style={{ margin: '0', lineHeight: '3rem', fontFamily: 'Domaine-display-condensed', fontSize: 56 }}
        data-testid='closeiframe-modal-heading'
      >
        {state.question?.name}
      </h1>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', flexShrink: 1 }}>
        <p style={{ fontSize: 18 }}>Select one of the options below.</p>
        <RequiredTag isErrored={errorState?.isErrored} showError={errorState?.hasInteracted} />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: 8, width: '100%' }}>
        {
          state?.choices?.map((choice, idx) => <OptionCard choice={choice} selected={selectedId === choice.id} setSelected={handleSelected} key={idx} />)
        }
      </div>

      <button className='btn btn-primary' onClick={handleAdd} >Add to basket</button>
    </div>
  );
};

const OptionCard = ({ choice, selected, setSelected }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      borderRadius: 2,
      backgroundColor: '#F7F5F4',
      padding: '18px 20px',
      gap: 16,
      justifyContent: 'space-between',
      width: '100%'
    }}
    onClick={() => setSelected(choice.id)}>
    <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
      {
        (selected) ?
          <img src={checkboxTicked} className="icon is-medium" data-testid='choice-selected' width={42} />
          :
          <img src={checkboxEmpty} className="icon is-medium" data-testid='choice-unselected' role='icon' width={42} />
      }
      <p style={{ fontSize: 20 }} className='domain-display' data-testid='choice-name'>{choice.name}</p>
    </div>
    {
      choice.cost ? <p className='choice-price'>{choice.cost.toFixed(2)}</p> : <></>
    }
    {getPeppers(choice.uiTags)}
  </div>
);