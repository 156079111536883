import React from "react";
import TagError from '../img/icons/tag-error.svg';
import TagValid from '../img/icons/tag-valid.svg';

const getClasses = (isErrored, showError) => {
    let classes = 'required-tag ';
    if (isErrored && showError) classes += 'has-error';
    else if (showError) classes += 'valid';
    return classes;
};

export const RequiredTag = ({ isErrored, showError }) => (
    <div className={getClasses(isErrored, showError)}>
        <div className={'required-tag-container'} data-testid={'required-tag'}>
            {
                showError &&
                <img src={showError && isErrored ? TagError : TagValid} data-testid={`required-tag-${isErrored ? 'invalid' : 'valid'}`}/>
            }
            <p>Required</p>
        </div>
    </div>
);