import { filterAvailableBundles } from "./bundleHelpers";

export const getAvailableCategories = (categories, bundles, dietFilters) => {
    const excludedSubCats = [];
    const availableCats = categories?.map(cat => {
        const subCategories = [...cat.subCategories].sort((a, b) => a.displayIndex - b.displayIndex);
        const reducedSubbies = subCategories.reduce((acc, cur) => {
            let newCat = { ...cur, itemsToRender: [], bundlesToRender: [] };
            const categoryBundles = filterAvailableBundles(bundles, cur?.name);
            newCat.bundlesToRender.push(...categoryBundles.filter(el => dietFilters.every(pref => el.dietPreferences.includes(pref))));
            newCat.itemsToRender.push(...cur.subProducts
                .filter(item => {
                    if (!dietFilters.length) return true;
                    if (dietFilters.every(pref => item.dietPreferences.includes(pref))) return true;
                })
            );

            // if (newCat.bundlesToRender.length === 0 && newCat.itemsToRender.length === 0) excludedSubCats.push(cur.name); // MV - disabled on purpose
            acc.push(newCat);
            return acc;
        }, [])
        return { ...cat, subCategories: reducedSubbies }
    });

    return [availableCats, excludedSubCats];
}