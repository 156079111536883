import { useSelector } from 'react-redux';
import { freshRelevance, freshTrackClick, gtmEvent } from '../helpers/commonHelpers';
import { getDefaultPortion, getCals, useQuickAddProperties } from '../helpers/basketHelpers';
import { useConfig } from '../helpers/useConfig';
import DietaryIcons from './DietaryIcons';
import { useAppSelector } from '../store/storeTypes';

const BasketUpsellCard = ({
  item,
  item: {
    name,
    imageUrl,
    dietPreferences,
    id,
    displayPrice,
    objectId
  },
  modalItem,
  addItem
}) => {
  const { enableFreshRecommendation } = useSelector(state => state.session.features);
  const { isDelivery } = useSelector(state => state.basket);
  const showCalories = useAppSelector(state => state.menu.showCalories);
  const config = useConfig();
  const {isCustomisable} = useQuickAddProperties(item);
  const handleCardClick = () => {
    gtmEvent(isDelivery ? 'Delivery_Add_Item_RFM' : 'Collection_Add_Item_RFM');
    if (!modalItem) {
      const { meta, ...itemToAdd } = item;
      const url = `order.pizzaexpress.com/${isDelivery ? 'delivery' : 'collection'}`;
      // Reset meta and remove outOfStock property so hashes match (meta is reset in applyDefaultSubproducts) - MV
      addItem(itemToAdd, isCustomisable);
      freshRelevance('pageChange', null, { 'sendBeacon': true });
      if (enableFreshRecommendation && meta && Object.keys(meta).length > 0) {
        freshTrackClick(meta, url, objectId);
      }
    }
  };

  const defaultPortion = getDefaultPortion(item);

  return (
    <>
      <div
        key={id}
        className="m-0 h100 is-flex is-flex-direction-column is-align-self-stretch basket-upsell-container"
      >
        <div
          className={'p-0 is-12-desktop upsell-card-primary-image'}
        >
          <figure className="image is-3by2-desktop is-3by2-touch">
            <div
              className="background-image"
              style={{
                backgroundImage: 'url(' + imageUrl + ')',
              }}
            />
          </figure>
        </div>
        <div
          className="column is-flex basket-upsell-card-details"
          style={{ 'flexGrow': 100 }}
        >
          <div className="mb-0 columns p-4 is-mobile" style={{ gap: 10 }}>
            <div className="column upsell-card-info-container p-0" >
              <div className="is-size-5 mb-1 is-text-weight-bold is-prewrap">
                {dietPreferences
                  && <div className="level mt-2 is-mobile is-justify-content-center is-pulled-right">
                    <DietaryIcons style={{ alignItems: 'flex-start', marginTop: '-4px' }} icons={dietPreferences} />
                  </div>}
                {name}
              </div>
              {
                defaultPortion &&
                <div className='is-prewrap' style={{ marginBottom: 4 }}>{defaultPortion.name}</div>
              }
              <div
                className="price-calory-wrapper"
              >
                <p className="is-size-7 mr-4 price">{config?.CURRENCY_SYMBOL}{displayPrice?.toFixed(2)}</p>
                {showCalories && <p className="is-size-7 kcals">{getCals(item, null, true)}</p>}
              </div>
            </div>
            <div className='column is-4 p-0 upsell-card-secondary-image'>
              <img src={imageUrl} />
            </div>
          </div>
          <div className='block'
            style={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              justifyContent: 'end',
              alignItems: 'center'
            }}
          >
            <button
              onClick={handleCardClick}
              className='btn btn-secondary basket-upsell-button'
              data-testid={'tomenu'}
              style={{ width: '100%', maxWidth: '100%' }}
            >
              Add to basket
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BasketUpsellCard;
