import { useEffect, useRef, useState } from 'react';
import CustomiseHeader from '../components/CustomiseHeader';
import { useSelector } from 'react-redux';
import { useAppSelector } from '../../../../src/store/storeTypes';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import BundleCustomiseSection from '../components/BundleCustomiseSection';
import { andAdd, andDecrease, andIncrease, bundleDisplayIdxComparer, calcBundleCost, findProduct, isBundleInStock, removeDisabledItem, removeOptions } from '../../../helpers/bundleHelpers';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { historyMW } from '../../../helpers/routingHelpers';
import { useAddBundleToBasketMutation, useUpdateBasketMutation } from '../../../services/basket.api';
import hash from 'object-hash';
import PopupBannerController from '../../../components/PopupBannerController';
import { useConfig } from '../../../helpers/useConfig';
import { gtmEvent } from '../../../helpers/commonHelpers';
import chevronLeft from '../../../img/icons/ChevronLeft24.svg';
import minimize from '../../../img/icons/minus-outlined-white.svg';
import plus from '../../../img/icons/plus-outlined-white.svg';
import Footer from '../../../components/Footer';

const dietIcon = [];

export const getBundle = (bundles, name) => {
  let bundle;
  let bundleSections;
  if (bundles) {
    bundle = bundles.find(el => el.name.trim() === name);
    if (bundle) {
      bundleSections = [...bundle?.sections];
      bundleSections.sort(bundleDisplayIdxComparer);
      return { ...bundle, sections: bundleSections };
    }
  }
};

export const getCustomBundle = (bundle, basketItems, customID, removeOptions) => {
  const customBundle = basketItems.find(el => el.customID === customID);
  if (customID && customBundle) {
    return customBundle;
  } else {
    return removeOptions(bundle);
  }
};

const Bundle = ({ refs, restaurantId, refreshBasket }) => {
  const config = useConfig();
  const basketBundles = useSelector(state => state.basket.bundles);
  const menuBundles = useSelector(state => state.menu.bundles);
  const outOfStockItems = useSelector(state => state.menu.outOfStockItems);
  const isDelivery = useSelector(state => state.basket.isDelivery);
  const basket = useSelector(state => state.basket);
  const { inApp, customer } = useSelector(state => state.session);
  const [params] = useSearchParams();
  const { customId } = Object.fromEntries(params);
  const navigate = useNavigate();
  const { itemId: name } = useParams();
  const { width: screenWidth } = useWindowDimensions();
  const bundle = getBundle(menuBundles, name);
  const customBundle = getCustomBundle(bundle, basketBundles, customId, removeOptions);
  const [customisedItem, setCustomisedItem] = useState(customBundle);
  const [quantity, setQuantity] = useState(customBundle?.quantity || 1);
  const [invalidSections, setInvalidSections] = useState([]);
  const [showErrors, setShowErrors] = useState(false);
  const [disabledItems, setDisabledItems] = useState([]);
  const [addBundleToBasket] = useAddBundleToBasketMutation();
  const [updateBasket] = useUpdateBasketMutation();
  const { items, numberOfItems } = useSelector(state => state.basket);
  const [isOpen, setIsOpen] = useState(false);
  const [currentPrice, setCurrentPrice] = useState(0);
  const pageRef = useRef(null);
  const { isTabletScreen } = useSelector(state => state.session);
  const customiseBannerRef = useRef(null);
  const showCalories = useAppSelector(state => state.menu.showCalories);
  const customisePageRef = useRef();
  const navHeight = refs.current.navRef?.offsetHeight ?? 0;
  const brand = config?.BRAND;

  const handleCustomisation = (customisedItem) => {
    const event = {
      channel: inApp ? 'App' : 'Web',
      currency: brand === 'PE' ? 'GBP' : 'EUR',
      value: basket.total,
      clickAndCollectEvent: isDelivery
        ? 'Delivery_Product_Details_Clicked'
        : 'Collection_Product_Details_Clicked',
    };

    const params = {
      item_name: customisedItem.name,
      price: calcBundleCost(customisedItem),
      quantity: quantity,
      item_brand: brand,
      item_category: customisedItem.category
    };

    if (customer?.customerSessionToken?.pizzaExpressId) {
      params.customerId = customer.customerSessionToken.pizzaExpressId;
    }

    const options = customisedItem.sections.flatMap(el =>
      el.products.map(pr => `${pr.product.name} x ${pr?.product?.quantity ? pr.product.quantity : 1}`)
    );
    options.forEach((option, index) => {
      params[`item_category${index + 1}`] = option;
    });

    event.items = params;
    gtmEvent('product_details_event', event);

    setCustomisedItem(customisedItem);
  };

  useEffect(() => {
    if (customBundle && !customisedItem) {
      setCustomisedItem(customBundle);
    }
  }, [customBundle]);

  const handleSelect = (product, customisedBundle, sectionId, maxItems) => {
    let customisedItem = findProduct(andAdd, product, customisedBundle, sectionId, maxItems);
    handleCustomisation(customisedItem, product?.product?.name ?? '');
  };

  const handleIncrease = (product, customisedBundle, sectionId, maxItems) => {
    let customisedItem = findProduct(andIncrease, product, customisedBundle, sectionId, maxItems);
    handleCustomisation(customisedItem, product?.product?.name ?? '');
  };

  const handleDecrease = (product, customisedBundle, sectionId) => {
    let customisedItem = findProduct(andDecrease, product, customisedBundle, sectionId);
    handleCustomisation(customisedItem, product?.product?.name ?? '');
  };

  if (bundle && !isBundleInStock(bundle, outOfStockItems)) {
    navigate(-1);
  }

  useEffect(() => {
    if (customisedItem) {
      setCustomisedItem(removeDisabledItem(disabledItems, customisedItem));
    }
  }, [disabledItems]);

  const addItemToBasket = async () => {
    const sections = Array.from(document.getElementsByClassName('customise-section'));
    const firstInvalidSectionNode = sections.filter(el => invalidSections.find(s => el.dataset.sectionErrorId === s))[0];
    if (invalidSections.length && firstInvalidSectionNode) {
      scrollToInvalidSection(firstInvalidSectionNode);
      setShowErrors(true);
    } else if (customId) {
      await updateBasket({
        restaurantId,
        numberOfItems,
        items,
        bundles: basketBundles.map(bundle => bundle.customID === customId ? { ...customisedItem, quantity, customID: hash(customisedItem) } : bundle)
      });
      refreshBasket();
      historyMW.push('/basket', isDelivery, navigate);
    } else {
      const response = await addBundleToBasket({ ...customisedItem, calculatedCost: currentPrice * quantity, quantity, customID: hash(customisedItem), restaurantId });
      if (response?.data?.status === 'ERROR') {
        setIsOpen(true);
      } else {
        refreshBasket();
        historyMW.push(`/menu/${restaurantId}`, isDelivery, navigate);
      }
    }
    const event = {
      channel: inApp ? 'App' : 'Web',
      currency: brand === 'PE' ? 'GBP' : 'EUR',
      value: basket.total,
      clickAndCollectEvent: isDelivery
        ? 'Delivery_Add_Items'
        : 'Collection_Add_Items',
    };

    const params = {
      item_name: customisedItem.name,
      price: calcBundleCost(customisedItem),
      quantity: quantity,
      item_brand: brand,
      item_category: customisedItem.category
    };

    if (customer?.customerSessionToken?.pizzaExpressId) {
      params.customerId = customer.customerSessionToken.pizzaExpressId;
    }

    const options = customisedItem.sections.flatMap(el =>
      el.products.map(pr => `${pr.product.name} x ${pr?.product?.quantity ? pr.product.quantity : 1}`)
    );
    options.forEach((option, index) => {
      params[`item_category${index + 1}`] = option;
    });

    event['items'] = [params];

    gtmEvent('add_to_cart', event);
  };

  useEffect(() => {
    if (customisedItem) {
      setCurrentPrice(calcBundleCost(customisedItem));
    }
  }, [customisedItem]);

  const handleRequiredScrolling = (customisedObjId) => {
    const sections = Array.from(document.getElementsByClassName('customise-section'));
    const firstInvalidSectionNode = sections.filter(el => invalidSections.find(s => el.dataset.sectionErrorId === s) && el.dataset.sectionErrorId !== customisedObjId)[0];
    if (invalidSections.length && firstInvalidSectionNode) {
      scrollToInvalidSection(firstInvalidSectionNode);
    }
  }

  const scrollToInvalidSection = (invalidSection) => {
    const offsetHeight =
      refs.current.navRef?.clientHeight +
      customiseBannerRef?.current?.clientHeight +
      (!isTabletScreen ? 40 : 0);

    const modalScrollContainer = customisePageRef?.current;
    const currentScrollY = modalScrollContainer?.scrollTop || 0;

    const scrollOptions = {
      top: currentScrollY + invalidSection.getBoundingClientRect().top - offsetHeight,
      behavior: 'smooth',
      block: 'center'
    };

    modalScrollContainer?.scrollTo(scrollOptions);
  };

  return (
    <div
      ref={customisePageRef}
      style={{
        width: '100vw',
        position: 'fixed',
        top: navHeight ?? 0,
        bottom: 0,
        left: 0,
        zIndex: 10000,
        backgroundColor: '#eae7e4',
        overflow: 'auto'
      }}
    >
      <div className='customise-banner' style={{ top: 0 }} ref={customiseBannerRef}>
        {!inApp ? (
          <button className={'btn-customise-back'} onClick={() => navigate(-1)}>
            <img src={chevronLeft} alt="Chevron" />
            Back to {customId ? 'basket' : 'menu'}
          </button>
        ) : (
          <div></div>
        )}
      </div>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <div className='customise-container'
          ref={pageRef}
          style={inApp ? { paddingBottom: `${refs.current.bannerRef?.clientHeight + (!isTabletScreen ? 24 : 0)}px`, minHeight: `calc(100vh - ${refs.current.bannerRef?.clientHeight ?? 0}px - ${refs.current.navRef?.clientHeight ?? 0}px)` } : {}}>
          {customisedItem &&
            <div className='customise-product-info-container' style={isTabletScreen ? {} : { top: (customiseBannerRef?.current?.clientHeight ?? 0), position: 'sticky' }}>
              <img className='customise-product-image' src={bundle?.imageUrl} alt='food item' />
              <div className='customise-product-info'>
                <CustomiseHeader
                  isBundle
                  name={bundle?.name}
                  description=''
                  price={bundle?.basePrice}
                  customisedItem={customisedItem}
                  setShowErrors={setShowErrors}
                  invalidSections={invalidSections}
                  dieAdtIcon={dietIcon}
                  quantity={quantity}
                  setQuantity={setQuantity}
                  restaurantId={restaurantId}
                  screenWidth={screenWidth}
                  edit={!!customId}
                  setDisabledItems={setDisabledItems}
                  refreshBasket={refreshBasket}
                  addToBasket={addItemToBasket}
                  isOpen={isOpen}
                  inApp={inApp}
                  currentPrice={currentPrice}
                  showCalories={showCalories}
                />
              </div>
            </div>
          }
          <div className='customise-card'>
            {customisedItem &&
              bundle?.sections.sort(bundleDisplayIdxComparer).map((el, idx) => (
                <BundleCustomiseSection
                  bundleSection={el}
                  customisedBundle={customisedItem}
                  key={`${idx}`}
                  handleSelect={handleSelect}
                  handleIncrease={handleIncrease}
                  handleDecrease={handleDecrease}
                  invalidSections={invalidSections}
                  setInvalidSections={setInvalidSections}
                  showErrors={showErrors}
                  compare={bundleDisplayIdxComparer}
                  disabledItems={disabledItems}
                  outOfStockItems={outOfStockItems}
                  handleRequiredScrolling={handleRequiredScrolling}
                  showCalories={showCalories}
                />
              ))
            }
          </div>
        </div>
        <div ref={r => refs.current.customiseFooterSpacer = r} style={{ width: '100%' }} />
        <PopupBannerController refs={refs} justifyEnd parentRef={pageRef}>
          <div className='customise-popup-container'>
            <div className='customise-card-amount-container'>
              {
                <>
                  <img
                    src={minimize}
                    className="icon is-medium"
                    role='icon'
                    style={{ cursor: quantity > 1 ? 'pointer' : 'none', opacity: quantity > 1 ? '1' : '0.2' }}
                    onClick={() => setQuantity(prev => prev > 1 ? prev - 1 : prev)}
                  />
                  <p className="customise-card-amount-quantity" style={{ color: 'white' }}>{quantity}</p>
                  <img
                    src={plus}
                    className="icon is-medium"
                    role='icon'
                    style={{ cursor: 'pointer' }}
                    onClick={() => setQuantity(prev => prev + 1)}
                  />
                </>
              }
            </div>
            <button className='checkout-btn btn btn-primary' style={{ width: 'min-content' }} onClick={addItemToBasket}>
              Add to basket - {config?.BRAND === 'PE' ? '£' : '€'}{(currentPrice * quantity).toFixed(2)}
            </button>
          </div>
        </PopupBannerController>
      </div>
      {!inApp && <Footer refs={refs} inApp={inApp} spacerRefName={'customiseFooterSpacer'} refName={'customiseFooter'} />}
    </div>
  );
};

export default Bundle;
