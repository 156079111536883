import React, { useEffect, useState } from 'react';
import Close from '../../img/icons/close.svg';
import locked from './../../img/loyalty/lock.svg';
import beer from './../../img/loyalty/modal/beer.svg';
import cocktail from './../../img/loyalty/modal/cocktail.svg';
import coffee from './../../img/loyalty/modal/coffee.svg';
import cookbook from './../../img/loyalty/modal/cookbook.svg';
import deliveryAndCollection from './../../img/loyalty/modal/delivery & collection.svg';
import dolcetti from './../../img/loyalty/modal/dolcetti.svg';
import doughballs from './../../img/loyalty/modal/doughballs.svg';
import familyBundle from './../../img/loyalty/modal/family bundle.svg';
import generic from './../../img/loyalty/modal/generic.svg';
import hearts from './../../img/loyalty/modal/hearts.svg';
import jazz from './../../img/loyalty/modal/jazz.svg';
import kids1 from './../../img/loyalty/modal/kids 1.svg';
import pizza1 from './../../img/loyalty/modal/pizza 1.svg';
import pizza2 from './../../img/loyalty/modal/pizza 2.svg';
import pizza3 from './../../img/loyalty/modal/pizza 3.svg';
import pizza4 from './../../img/loyalty/modal/pizza 4.svg';
import pizzaSlice1 from './../../img/loyalty/modal/pizza slices 1.svg';
import pizzaSlice2 from './../../img/loyalty/modal/pizza slices 2.svg';
import present from './../../img/loyalty/modal/present.svg';
import side from './../../img/loyalty/modal/side.svg';
import softDrink from './../../img/loyalty/modal/soft drink.svg';
import special1 from './../../img/loyalty/modal/special 1.svg';
import special2 from './../../img/loyalty/modal/special 2.svg';
import special3 from './../../img/loyalty/modal/special 3.svg';
import water from './../../img/loyalty/modal/water.svg';
import wineGlass from './../../img/loyalty/modal/wine glass.svg';
import wine from './../../img/loyalty/modal/wine.svg';

const imageLookup = {
  ['beer'] : beer,
  ['cocktail'] : cocktail,
  ['coffee'] : coffee,
  ['cookbook'] : cookbook,
  ['delivery & collection'] : deliveryAndCollection,
  ['dolcetti'] : dolcetti,
  ['doughballs'] : doughballs,
  ['family bundle'] : familyBundle,
  ['generic'] : generic,
  ['hearts'] : hearts,
  ['jazz'] : jazz,
  ['kids 1'] : kids1,
  ['pizza 1'] : pizza1,
  ['pizza 2'] : pizza2,
  ['pizza 3'] : pizza3,
  ['pizza 4'] : pizza4,
  ['pizza slices 1'] : pizzaSlice1,
  ['pizza slices 2'] : pizzaSlice2,
  ['present'] : present,
  ['side'] : side,
  ['soft drink'] : softDrink,
  ['special 1'] : special1,
  ['special 2'] : special2,
  ['special 3'] : special3,
  ['water'] : water,
  ['wine glass'] : wineGlass,
  ['wine'] : wine
};

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const getMonth = date => months[date.getMonth()];

export const dayNTH = d => {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};


export const formatDateString = (date, abrv) => {
  if (abrv) {
    let day = date.getDate();
    return day + dayNTH(day) + ' ' + getMonth(date) + ' ' + date.getFullYear();
  } else {
    return date.getDate() + ' ' + getMonth(date) + ' ' + date.getFullYear();
  }
};

export const getValidityText = (lock, reward, offer) => {
  let validityText = reward?.rewardDetails?.validityText;
  if (lock && reward?.rewardDetails?.lockedValidityTextExpiryDate) {
    validityText = reward?.rewardDetails?.lockedValidityTextExpiryDate;
  }
  if (validityText) {
    validityText = validityText
      .replace('{{VOUCHER_EXPIRY}}', formatDateString(new Date(reward.validTo)))
      .replace('{{VOUCHER_ISSUANCE}}', formatDateString(new Date(reward.validFrom)));
  }
  return validityText || '';
};

export const getValidityTextComponents = (validityText) => {
  const validityTextWords = validityText.split(' ');
  const validityTextComponents = [];
  let inLink = false;
  let link = '';

  validityTextWords.forEach((word) => {
    if (word.startsWith('***')) {
      inLink = true;
    }
    if (inLink) {
      link += word.replace('***', '') + ' ';
      if (word.endsWith('***')) {
        inLink = false;
        validityTextComponents.push({ link: link.trimEnd() });
      }
    } else {
      validityTextComponents.push({ word });
    }
  });
  return validityTextComponents;
};

const LoyaltyModal = ({ onClose, reward, loyaltyRewardImage, showTermsAndConditions, inApp, isOffer }) => {
  const [validityText, setValidityText] = useState([]);
  const [slideOut, setSlideOut] = useState(false);
  const tierName = reward?.tierName === 'signUp' ? 'club member' : reward?.tierName;
  useEffect(() => {
    setValidityText(getValidityTextComponents(getValidityText(reward?.isLocked, reward)));
  }, [reward]);

  const onSlideOut = () => {
    if (inApp) return;
    setSlideOut(true);
    setTimeout(onClose, 250);
  };

  let rewardImage = null;
  try {
    rewardImage = imageLookup[loyaltyRewardImage?.image];
  }
  catch {
    console.warn(`Unable to load rewards image ${loyaltyRewardImage?.image}`);
  }

  return (
    <>
      {reward ? <>
        <div className={`loyalty-modal loyalty-modal-background is-relative ${reward.disabled ? 'is-disabled' : ''} ${reward.isLocked ? 'is-locked' : ''}  ${reward.rewardDetails.colourOfTilenbwConverted.toLowerCase()} ${slideOut ? 'slideOut' : ''}`}>
          {reward.isLocked && <div className="is-locked-icon is-absolute zi-3" style={{ width: 48, height: 48, top: 24 }}>
            <div className="icon-wrapper is-justify-content-center is-align-items-center" style={{ width: 48, height: 48 }}>
              <img alt="locked" src={locked} style={{ width: 46, height: 46 }} />
            </div>
          </div>}
          <div className="close-icon-wrapper hide-on-mobile" >
            <i className={`icon is-large ${reward.rewardDetails.nbwRewardTheme === 'White' ? 'invert' : ''}`} style={{ cursor: 'pointer' }} >
              <img src={Close} alt="close" onClick={onClose} />
            </i>
          </div>
          {
            !inApp &&
            <div className={`mobile-drag-bar-container  ${reward.rewardDetails.nbwRewardTheme === 'White' ? 'invert' : ''}`} onTouchMove={onSlideOut}>
              <div className='mobile-drag-bar'></div>
            </div>
          }
          <div className="loyalty-content">
            <div className="loyalty-modal-top-content zi-2">
              <div className="loyalty-modal-header">
                <div className="header-text">{isOffer ? 'EXCLUSIVE OFFER' :
                reward.rewardDetails.showTierName ? tierName.toUpperCase() + ' REWARD' : reward.rewardDetails.rewardDetailPageHeaderCopy}</div>
              </div>
              {loyaltyRewardImage && <div className="main-image">
                <img
                  className={reward.rewardDetails.nbwRewardTheme === 'White' ? 'invert' : ''}
                  src={rewardImage}
                  alt="close"
                />
              </div>}
              <div className="title">
                {reward.rewardDetails.rewardTitle.replace(/#/g, ' ').toUpperCase()}
              </div>
              <div className="text-content">
                {reward.rewardDetails.rewardDetailPageDescription}
              </div>
            </div>
            <div style={{ margin: '16px 24px', height: 1, width: 'calc(100% - 48px)', backgroundColor: reward.rewardDetails.nbwRewardTheme === 'White' ? 'white' : '#1C1A1A' }}></div>
            <div className="loyalty-modal-bottom-content" style={{ paddingBottom: inApp && 0, zIndex: 2, position: 'relative' }}>
              {<div>{validityText.map((component, idx) => {
                if (component.link) return <span key={idx}><a style={{ color: reward.rewardDetails.nbwRewardTheme === 'White' ? 'white' : '#1C1A1A' }} onClick={showTermsAndConditions}>{component.link}</a>{' '}</span>;
                if (component.word) return <React.Fragment key={idx}>{component.word}{' '}</React.Fragment>;
              })}</div>}
            </div>
          </div>
        </div>
      </> : null}
    </>

  );
};

export default LoyaltyModal;
