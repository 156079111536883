import React from 'react';
import errorIcon from './../../../img/icons/error.svg';
import { useState, useEffect, useRef } from 'react';
import search from '../../../img/icons/search.svg';

export const SearchInput = (props) => {
  const { label, isError, errorMessage, valueCallback, name, value, onLocationClick, placeholder, hideLocationIcon, showIcon, handleClick = () => {}, showNotification } = props;
  const [focus, setFocus] = useState(false);
  const inputRef = useRef(null);

  const inputGrandParentStyles = () => {
    const newStyles = {
      boxShadow: `${focus ? 'rgb(28 26 26)' : 'transparent'} 0 0 0 2px`,
    };
    return newStyles;
  };

  const onClick = (e) => {
    e.stopPropagation();
    handleClick();
  }

  useEffect(() => {
    if (showNotification) {
      inputRef.current?.blur();
    }
  }, [showNotification]);

  return (
    <>
      <div key={name} className={`block ${isError && errorMessage ? 'has-error mb-3' : 'mb-0-desktop mb-0-tablet mb-0-mobile'}`}>
        {label && <div data-testid="searchinput-label" className="label">{label}</div>}
        <div
          className="field mb-0 has-addons has-background-grey-lightest rounded-11"
          style={inputGrandParentStyles()}
        >
          <div className="control is-expanded" style={{ position: 'relative'}}>
            <input
              ref={inputRef}
              data-testid="searchinput-input"
              placeholder={placeholder}
              value={value}
              onChange={(event) => valueCallback(event.target.value)}
              className="input"
              type="text"
              onFocus={() => setFocus(true)}
              onBlur={() => {
                setFocus(false);
              }}
              // JS - NBW - Modified padding left/right to 24px because of the additional 8px padding in the designs (16+8px)
              style={isError && errorMessage ? { border: '2px solid #9E1A30', backgroundColor: '#FAD9D0', borderRadius: '56px', padding: '13px 16px 13px 24px', height: 'auto' } : {  padding: '13px 16px 13px 24px', height: 'auto' }}
              id='searchInput'
              key='searchInput'
            />
            {
              showIcon &&
              <div style={{ position: 'absolute', right: '0', top: '0', bottom: '0', zIndex: 100, display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={onClick} data-testid='searchInput-searchIcon'>
                <div style={{ padding: '0 10px', cursor: 'pointer' }}>
                  <i className="icon is-large" >
                    <img className="img" src={search} alt="search" />
                  </i>
                </div>
              </div>
            }
          </div>
        </div>
        {isError && errorMessage && (
          <div className="is-mobile is-family-code is-error pl-2 mt-1" style={{ display: 'flex', alignItems: 'top', paddingRight: '0.5rem' }}>
            <div style={{ marginRight: 8 }}>
              <img src={errorIcon} alt="error" style={{width: 18, height: 18}}/>
            </div>
            <div className="level-item is-growable is-shrinkable is-justify-content-flex-start is-align-top">
              <div data-testid="searchinput-error" className="error-message">{errorMessage}</div>
            </div>
          </div>
        )}
      </div>
      {
        !hideLocationIcon &&
        <div className="geolocation-wrapper">
          <button
            onClick={onLocationClick}
            className="btn btn-secondary btn-geoLocation"
            id="geolocationButton"
            data-testid='geolocationbutton'
          >
            <i className="icon is-large">
              <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.5 3V1M3.5 11H1.5M11.5 19V21M19.5 11H21.5M19 11C19 15.1421 15.6421 18.5 11.5 18.5C7.35786 18.5 4 15.1421 4 11C4 6.85786 7.35786 3.5 11.5 3.5C15.6421 3.5 19 6.85786 19 11ZM14.5 11C14.5 12.6569 13.1569 14 11.5 14C9.84315 14 8.5 12.6569 8.5 11C8.5 9.34315 9.84315 8 11.5 8C13.1569 8 14.5 9.34315 14.5 11Z"
                  stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </i>
            <span>Use my location</span>
          </button>
        </div>
      }
    </>
  );
};

export default SearchInput;
