import { useEffect, useState } from 'react';
import { useConfig } from '../helpers/useConfig';
import peLogo from './../img/pelogo.svg';
import peLogoApp from './../img/common/Loader_360x360.gif';
import miLogo from './../img/milogo.png';

const Loading = ({ show, inApp } : { show: boolean, inApp: boolean}) => {
  const config = useConfig();
  const [state, setState] = useState({
    message: ['Loading your menu...', 'Verifying your details...', 'Almost there...'],
    index: 0,
  });

  useEffect(() => {
    if (!inApp) return;

    setTimeout(() => {
      setState(prev => ({
        ...prev,
        index: state.index === prev.message.length - 1 ? 0 : state.index + 1,
      }));
    }, 3000);
  }, [state.index]);

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: show ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: inApp ? '20px' : '1rem',
        marginTop: inApp ? -80 : -100
      }}
    >
      {
        config?.BRAND === 'PE' || window.location.hostname.includes('.pizzaexpress.com')
            ? inApp
              ? <img style={{ margin: 0, width: 120, height: 120 }} src={peLogoApp} alt='logo' />
              : <img style={{ margin: 0 }} className="pulsing-image" src={peLogo} alt='logo' />
            : <img style={{ margin: 0 }} className="pulsing-image milano-image" src={miLogo} alt='logo' />
      }
      <h2 style={{ margin: 0, color: '#1c1a1a', fontSize: inApp ? 14 : 18 }}>{inApp ? state.message[state.index] : 'Loading'}</h2>
    </div>
  );
}

export default Loading;