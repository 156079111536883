import React from 'react';
import { useSelector } from 'react-redux';


const CookiesBanner = ({ visible, inApp, didomiObject, setDidomiObject }) => {
  const { isTabletScreen } = useSelector(state => state.session);
  return null;
  //Replaced with Didomi banner
  //return (
  //  <div style={{
  //    position: 'fixed',
  //    bottom: 0,
  //    left: 0,
  //    zIndex: 10000,
  //    width: '100vw',
  //    height: 'auto',
  //    backgroundColor: '#fff',
  //    paddingLeft: 10,
  //    paddingRight: 10,
  //    boxShadow: '0 0 5px #aaa'
  //  }}>
  //    <div className={`container is-flex ${isTabletScreen ? 'is-flex-direction-column p-4' : 'p-3'} is-justify-content-center is-align-items-center is-max-desktop`} style={{gap: 5}}>
  //      <p className={`is-size-6 ${!isTabletScreen && 'has-text-centered'} is-flex-grow-3`} style={{color: '#3f2021'}}>
  //                    Beyond pizza, there are cookies – we use the non-edible kind on this site. 
  //                    Some of these cookies tell us how you use this site to help you get the most out of it – if you’re happy with that, 
  //                    click <a href='#' onClick={() => acceptCookies()}>Accept</a> For information on the cookies used, click on <br/> <a href='https://www.pizzaexpress.com/about-us/cookie-policy' rel="noopener noreferrer" target='_blank'>More about cookies</a>
  //      </p>
  //      <button 
  //        className='btn btn-primary mx-3 is-fullwidth' 
  //        style={{paddingTop: '1.5rem', paddingBottom: '1.5rem', fontSize: 18}}
  //        onClick={() => acceptCookies()}
  //      >
  //                    Accept
  //      </button>
  //    </div>
  //  </div>
  //);
};
// return null;
// };

export default CookiesBanner;