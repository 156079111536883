import React from 'react';
import chevronUp from './../img/icons/chevronUpNew.svg';

const ExpandButton = ({callback, invert, size = 'is-small'}) => (
  <button onClick={callback} style={{ height: '1.5rem', width: '1.5rem', backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}>

    <i className={`icon ${size}`} style={{ transform: `rotate(${invert ? '180deg' : 0})` }}>
      <img src={chevronUp} alt="open-close control" />
    </i>

  </button>
);

export default ExpandButton;
