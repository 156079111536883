import extraSpicyImg from '../img/icons/dietary/Extra-Spicy.svg';
import glutenFreeImg from '../img/icons/dietary/Gluten-Free.svg';
import spicyImg from '../img/icons/dietary/Spicy.svg';
import veganImg from '../img/icons/dietary/Vegan.svg';
import vegetarianImg from '../img/icons/dietary/Vegetarian.svg';

const imageLookup = {
  ['Extra-Spicy'] : extraSpicyImg,
  ['Gluten-Free'] : glutenFreeImg,
  ['Spicy'] : spicyImg,
  ['Vegan'] : veganImg,
  ['Vegetarian'] : vegetarianImg,
};

const DietaryIcons = ({icons, style, iconSize = 24}) => {
  return icons?.length ?
    <div className="dietary-icons" style={{ display: 'flex', alignItems: 'flex-start', gap: 8, marginTop: '4px', ...(style && style) }}>
      {
        icons.map((option, idx) => {
          const icon = imageLookup[option];
          if (!icon) return <></>;
          return (
            <img
              key={option + idx}
              role="img"
              alt={option}
              style={{height: iconSize}}
              data-testid={`dietary-icon-${option}`}
              src={icon}
            />
          );
        }
        )
      }
    </div>
    :
    null;
};
export default DietaryIcons;