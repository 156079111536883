import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import ChangeRestaurantModal from '../components/modals/ChangeRestaurantModal';
import DietaryFilterModal from '../components/modals/DietaryFilterModal';
import UpsellModal from '../components/modals/UpsellModal';
import MeetMinimumOrder from '../components/modals/MeetMinimumOrder';
import WelcomeToPEModal from '../components/modals/WelcomeToPEModal';
import { useDispatch } from 'react-redux';
import CollectionTimeErrorModal from '../components/modals/CollectionTimeErrorModal';
import { setCollectionTime, setHasShownEmployeeModal } from '../store/sessionSlice';
import { useLazyGetUpsellItemsQuery } from '../services/basket.api';
import NotificationModal from '../components/modals/NotificationModal';
import LoyaltyModal from '../components/modals/LoyaltyModal';
import TemporarilyClosedModal from '../components/modals/TemporarilyClosedModal';
import ChangePostcodeModal from '../components/modals/ChangePostcodeModal';
import TermsAndConditionsModal from '../components/modals/TermsAndConditionsModal';
import useWindowDimensions from '../hooks/useWindowDimensions';
import GenericModal from '../components/modals/GenericModal';
import EmployeeDiscountModal from '../components/modals/EmployeeDiscountModal';
import DeliveryErrorModal from '../components/modals/DeliveryErrorModal';
import { DeliveryUpdatedModal } from '../components/modals/DeliveryTimeUpdatedModal';
import { useSendToHome } from '../hooks/useSendToHome';
import { CloseIFrameModal } from '../components/modals/CloseIFrameModal';
import { LeaveOOPModal } from '../components/modals/LeaveOOPModal';
import { DraggableModal } from '../components/modals/DraggableModal';
import exclamationMark from '../../src/img/icons/exclamationMark.svg';
import questionMark from '../../src/img/common/questionmark.svg';
import { ChoiceModal } from '../components/modals/ChoiceModal';
import { MWModal } from '../components/modals/Modal';

const warning = new Image();
warning.src = exclamationMark
const question = new Image();
question.src = questionMark

export const DietFilterModalWrapper = ({ dietModalIsOpen, setDietModalIsOpen, screenWidth, inApp }) => {
  const windowDimensions = useWindowDimensions();
  const closeModal = () => {
    setDietModalIsOpen(false);
  };
  if (!dietModalIsOpen) return <></>;
  if (inApp || !inApp && screenWidth < 550) {
    return (
      <DraggableModal
        show={dietModalIsOpen}
        onClose={closeModal}
        style={{backgroundColor: 'white', padding: 0}}
      >
        <DietaryFilterModal inApp={inApp} onClose={closeModal} screenWidth={screenWidth} />
      </DraggableModal>
    );
  }
  return (
    <MWModal
      isOpen={dietModalIsOpen}
      onRequestClose={closeModal}
      style={stylesDietFilter(windowDimensions)}
      ariaHideApp={false}
    >
      <DietaryFilterModal onClose={closeModal} screenWidth={screenWidth} />
    </MWModal>
  );
};

export const UpsellModalWrapper = ({
  upsellModalOpen,
  setUpsellModalOpen,
  screenWidth,
  addItem,
  removeItem,
  navigateTo,
  restaurantID,
  outOfStockItems,
  refreshBasket,
  inApp }) => {
  const [getUpsellItems, { data, isSuccess, isLoading, isFetching, isError }] = useLazyGetUpsellItemsQuery();

  useEffect(() => {
    if (upsellModalOpen && restaurantID) {
      getUpsellItems({ id: restaurantID, type: 'Dips' }, false);
    }
  }, [upsellModalOpen, restaurantID]);

  if (!data?.dips?.length && isSuccess && upsellModalOpen) navigateTo('/basket');
  useEffect(() => {
    if (isError) {
      navigateTo('/basket');
      setUpsellModalOpen(false);
    }
  }, [isError]);

  if (inApp || !inApp && screenWidth < 550) return (
    <DraggableModal
      show={upsellModalOpen && (inApp || !inApp && screenWidth < 550) && !isLoading && !isFetching && isSuccess}
      isLoading={isLoading || isFetching}
      onClose={() => setUpsellModalOpen(false)}
      style={{padding: '0', backgroundColor: '#fff'}}
    >
      <UpsellModal
        upsell={data}
        isSuccess={isSuccess}
        onClose={() => setUpsellModalOpen(false)}
        screenWidth={screenWidth}
        addItem={addItem}
        removeItem={removeItem}
        navigateTo={navigateTo}
        outOfStockItems={outOfStockItems}
        isLoading={isLoading}
        refreshBasket={refreshBasket}
        refetch={getUpsellItems}
        restaurantID={restaurantID}
        inApp={inApp}
      />
    </DraggableModal>
  );
  return (
    <Modal
      isOpen={upsellModalOpen && !isLoading && !isFetching && isSuccess}
      onRequestClose={() => setUpsellModalOpen(false)}
      style={stylesUpsellModal}
      ariaHideApp={false}
    >
      <UpsellModal
        upsell={data}
        isSuccess={isSuccess}
        onClose={() => setUpsellModalOpen(false)}
        screenWidth={screenWidth}
        addItem={addItem}
        removeItem={removeItem}
        navigateTo={navigateTo}
        outOfStockItems={outOfStockItems}
        isLoading={isLoading}
        refreshBasket={refreshBasket}
        refetch={getUpsellItems}
        restaurantID={restaurantID}
      />
    </Modal>
  );
};

export const MinimumOrderModalWrapper = ({
  isOpen,
  setIsOpen,
  title,
  message,
  testId,
  navigateTo,
  restaurantId,
  inApp,
  gtmEvent,
  isDelivery
}) => {
  const handleContinue = () => {
    gtmEvent(isDelivery ? 'Delivery_Add_More_Items' : 'Collection_Add_More_Items');
    navigateTo(`/menu/${restaurantId}`);
  };

  if (inApp)
    return (
      <DraggableModal
        onClose={handleContinue}
        show={isOpen}
        style={{ padding: '0.5rem 1.5rem 0'}}
      >
        <MeetMinimumOrder
          testId={testId}
          onClose={handleContinue}
          navigateTo={navigateTo}
          title={title}
          message={message}
          restaurantId={restaurantId}
          warning={warning}
        />
      </DraggableModal>
    );
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => { }}
      style={stylesUpsellModal}
      ariaHideApp={false}
    >
      <MeetMinimumOrder
        testId={testId}
        onClose={handleContinue}
        navigateTo={navigateTo}
        title={title}
        message={message}
        restaurantId={restaurantId}
        warning={warning}
      />
    </Modal>
  );
};

export const NotificationModalWrapper = ({ show, onClose, notificationState, inApp }) => {
  const [contentState, setContentState] = useState(false);
  useEffect(() => { // Prevents content of modal disappearing before modal has transitioned out - MV
    if (show) {
      setContentState(show);
    } else {
      setTimeout(() => setContentState(show), 300)
    }
  }, [show]);

  if (inApp) return (
    <DraggableModal
      show={show}
      onClose={onClose}
    >
      <NotificationModal onClose={onClose} notificationState={contentState} inApp={inApp} warning={warning} />
    </DraggableModal>
  );
  return (
    <Modal
      isOpen={!!show}
      onRequestClose={onClose}
      style={stylesNotification}
      ariaHideApp={false}
    >
      <NotificationModal onClose={onClose} notificationState={notificationState} />
    </Modal>
  );
};

export const CloseIFrameModalWrapper = ({ show, onClose, redirect, closeIframe, inApp }) => {
  if (inApp) return (
    <DraggableModal
      show={show}
      onClose={onClose}
      style={{ padding: '8px 24px 0px' }}
    >
      <CloseIFrameModal onClose={onClose} redirect={redirect} closeIframe={closeIframe} inApp={inApp} question={question} />
    </DraggableModal>
  );
  return (
    <Modal
      isOpen={!!show}
      onRequestClose={onClose}
      style={stylesNotification}
      ariaHideApp={false}
    >
      <CloseIFrameModal onClose={onClose} redirect={redirect} closeIframe={closeIframe} />
    </Modal>
  );
};

export const LeaveOOPModalWrapper = ({ show, onClose, leaveOOPHandler, inApp }) => {
  if (inApp) return (
    <DraggableModal
      show={show}
      onClose={onClose}
    >
      <LeaveOOPModal onClose={onClose} leaveOOPHandler={leaveOOPHandler} inApp={inApp} />
    </DraggableModal>
  );
  return (
    <Modal
      isOpen={show}
      onRequestClose={onClose}
      style={stylesChangeRestaurant}
      ariaHideApp={false}
    >
      <LeaveOOPModal onClose={onClose} leaveOOPHandler={leaveOOPHandler} />
    </Modal>
  );
};

export const ChangeRestaurantModalWrapper = ({ show, onClose, onChangeRestaurant, inApp }) => {
  if (inApp) return (
    <DraggableModal
      show={show}
      onClose={onClose}
    >
      <ChangeRestaurantModal changeRestaurantHandler={onChangeRestaurant} inApp={inApp} onClose={onClose} question={question} />
    </DraggableModal>
  );
  return (
    <Modal
      isOpen={show}
      onRequestClose={onClose}
      style={stylesChangeRestaurant}
      ariaHideApp={false}
    >
      <ChangeRestaurantModal onClose={onClose} changeRestaurantHandler={onChangeRestaurant} />
    </Modal>
  );
};

export const DeliveryTimeUpdateModalWrapper = ({ show, onClose, restaurantId, deliveryEstimate, inApp }) => {
  if (inApp) return (
    <DraggableModal
      show={show}
      onClose={onClose}
    >
      <DeliveryUpdatedModal onClose={onClose} restaurantId={restaurantId} deliveryEstimate={deliveryEstimate} inApp={inApp} warning={warning} />
    </DraggableModal>
  );
  return (
    <Modal
      isOpen={show}
      onRequestClose={onClose}
      style={{
        ...stylesNotification,
        content: {
          ...stylesNotification.content,
          padding: '1.5rem 1.5rem 1rem',
        }
      }}
      ariaHideApp={false}
    >
      <DeliveryUpdatedModal onClose={onClose} restaurantId={restaurantId} deliveryEstimate={deliveryEstimate} />
    </Modal>
  );
};

export const WelcomeToPEModalWrapper = ({
  changeWelcomeModalIsOpen,
  setChangeWelcomeModalIsOpen,
  onAddItem,
  inApp
}) => {
  if (inApp) return (
    <DraggableModal
      show={changeWelcomeModalIsOpen}
      onClose={() => setChangeWelcomeModalIsOpen(false)}
    >
      <WelcomeToPEModal
        onClose={() => setChangeWelcomeModalIsOpen(false)}
        onAddItem={onAddItem}
        inApp={inApp}
      />
    </DraggableModal >
  );
  return (
    <Modal
      isOpen={changeWelcomeModalIsOpen}
      onRequestClose={() => setChangeWelcomeModalIsOpen(false)}
      style={stylesWelcome}
      ariaHideApp={false}
    >
      <WelcomeToPEModal
        onClose={() => setChangeWelcomeModalIsOpen(false)}
        onAddItem={onAddItem} />
    </Modal>
  );
};

export const EmployeeDiscountModalWrapper = ({ isOpen, inApp }) => {
  const dispatch = useDispatch();
  if (inApp) return (
    <DraggableModal
      show={true}
      onClose={() => dispatch(setHasShownEmployeeModal(true))}
    >
      <EmployeeDiscountModal
        closeTopPosition={20}
        closeRightPosition={20}
        onClose={() => dispatch(setHasShownEmployeeModal(true))}
        warning={warning}
        inApp={inApp}
      />
    </DraggableModal>
  );
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => dispatch(setHasShownEmployeeModal(true))}
      style={stylesWelcome}
      ariaHideApp={false}
    >
      <EmployeeDiscountModal
        closeTopPosition={20}
        closeRightPosition={20}
        onClose={() => dispatch(setHasShownEmployeeModal(true))} />
    </Modal>
  );
};

export const CollectionTimeErrorModalWrapper = ({ screenWidth, show, inApp }) => {
  const dispatch = useDispatch();
  if (inApp) return (
    <DraggableModal
      show={show}
      onClose={() => dispatch(setCollectionTime(''))}
    >
      <CollectionTimeErrorModal
        onClose={() => dispatch(setCollectionTime(''))}
        screenWidth={screenWidth}
        inApp={inApp}
        warning={warning} />
    </DraggableModal>
  );
  return (
    <Modal
      isOpen={show}
      onRequestClose={() => {
        dispatch(setCollectionTime(''));
      }}
      style={stylesCollectionTimeModal}
      ariaHideApp={false}
    >
      <CollectionTimeErrorModal onClose={() => {
        dispatch(setCollectionTime(''));
      }} screenWidth={screenWidth} />
    </Modal>
  );
};

export const DeliveryErrorModalWrapper = ({ show, onClose, message, inApp }) => {
  if (inApp) return (
    <DraggableModal
      show={show}
      onClose={onClose}
    >
      <DeliveryErrorModal onClose={onClose} message={message} inApp={inApp} warning={warning} />
    </DraggableModal >
  );
  return (
    <Modal
      isOpen={show}
      onRequestClose={onClose}
      style={stylesCollectionTimeModal}
      ariaHideApp={false}
    >
      <DeliveryErrorModal onClose={onClose} message={message} />
    </Modal>
  );
};

export const TemporarilyClosedModalWrapper = ({ show, isDelivery, restaurant, inApp }) => {
  const sendToHome = useSendToHome();
  if (inApp) return (
    <DraggableModal
      show={show}
      onClose={sendToHome}
    >
      <TemporarilyClosedModal
        isDelivery={isDelivery}
        onClose={sendToHome}
        inApp={inApp}
        warning={warning}
        restaurant={restaurant}
      />
    </DraggableModal>
  );
  return (
    <Modal
      isOpen={show}
      onRequestClose={sendToHome}
      style={{ ...stylesWelcome, content: { ...stylesWelcome.content, backgroundColor: '#EAE7E4' } }}
      ariaHideApp={false}
    >
      <TemporarilyClosedModal isDelivery={isDelivery} onClose={sendToHome} restaurant={restaurant} />
    </Modal>
  );
};

export const GenericModalWrapper = ({ isOpen, setIsOpen, title, message, buttonTitle, inApp }) => {
  if (inApp) return (
    <DraggableModal
      show={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <GenericModal onClose={() => setIsOpen(false)} title={title} message={message} buttonTitle={buttonTitle} inApp={inApp} warning={warning} />
    </DraggableModal>
  );
  return (
    <Modal
      isOpen={isOpen}
      style={stylesWelcome}
      ariaHideApp={false}
    >
      <GenericModal onClose={() => setIsOpen(false)} title={title} message={message} buttonTitle={buttonTitle} />
    </Modal>
  );
};

export const LoyaltyModalWrapper = ({ show, onClose, reward, loyaltyRewardImage, currentTier, inApp, isOffer }) => {
  const [showTcs, setShowTcs] = useState(false);
  const screenDimensions = useWindowDimensions();

  useEffect(() => {
    if (!show && showTcs) {
      setShowTcs(false);
    }
  }, [show]);

  if (inApp) return (
    <>
      <DraggableModal
        onClose={onClose}
        show={show}
        invertHandle={reward?.rewardDetails?.nbwRewardTheme === 'White'}
        backgroundClass={reward?.rewardDetails?.colourOfTilenbwConverted?.toLowerCase()}
      >
        <LoyaltyModal
          onClose={onClose}
          reward={reward}
          loyaltyRewardImage={loyaltyRewardImage}
          currentTier={currentTier}
          showTermsAndConditions={() => setShowTcs(true)}
          inApp={inApp}
          isOffer={isOffer}
        />
      </DraggableModal>
      <DraggableModal
        onClose={() => setShowTcs(false)}
        show={showTcs && show && inApp}
        bodyLock={false}
        contentCanScroll={true}
        style={{ backgroundColor: 'white' }}
      >
        <TermsAndConditionsModal
          tcs={reward?.rewardDetails?.tcs}
          onClose={onClose}
          inApp={inApp}
        />
      </DraggableModal>
    </>
  );
  return (
    <>
      <Modal
        isOpen={!showTcs && show}
        onRequestClose={onClose}
        style={screenDimensions.width < 590 ? stylesLoyaltyModalMobile : stylesLoyaltyModal}
        ariaHideApp={false}
      >
        <LoyaltyModal
          onClose={onClose}
          reward={reward}
          loyaltyRewardImage={loyaltyRewardImage}
          currentTier={currentTier}
          showTermsAndConditions={() => setShowTcs(true)}
          inApp={inApp}
          isOffer={isOffer}
        />
      </Modal>
      <Modal
        isOpen={showTcs && show}
        onRequestClose={onClose}
        style={stylesTermsAndConditionsModal(screenDimensions.width)}
        ariaHideApp={false}
      >
        <TermsAndConditionsModal
          tcs={reward?.rewardDetails?.tcs}
          onClose={onClose}
        />
      </Modal >
    </>
  );
};

export const ChangePostcodeModalWrapper = ({ isOpen, setIsOpen, inApp }) => {
  if (inApp) return (
    <DraggableModal
      show={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <ChangePostcodeModal inApp={inApp} onClose={() => setIsOpen(false)} question={question} />
    </DraggableModal >
  );
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={stylesWelcome}
      ariaHideApp={false}
    >
      <ChangePostcodeModal onClose={() => setIsOpen(false)} />
    </Modal>
  );
};

export const ChoiceModalWrapper = ({ isOpen, onClose, inApp, state }) => {
  const handleClose = () => {
    state.reject(false);
    onClose();
  }
  if (inApp) return (
    <DraggableModal
      show={isOpen}
      onClose={handleClose}
    >
      <ChoiceModal
        inApp={inApp}
        onClose={handleClose}
        state={state}
      />
    </DraggableModal >
  );
  return (
    <MWModal
      isOpen={isOpen}
      onClose={handleClose}
      style={stylesChoice}
      ariaHideApp={false}
    >
      <ChoiceModal
        onClose={handleClose}
        state={state}
      />
    </MWModal>
  );
};

const stylesCollectionTimeModal = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderWidth: 0,
    borderRadius: 10,
    maxWidth: '600px'
  },
  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.7)',
    zIndex: 100000
  }
};


const stylesDietFilter = (screenDimensions) => {
  return ({
    content: {
      right: 'auto',
      bottom: 'auto',
      backgroundColor: '#FFFFFF',
      borderWidth: 0,
      borderRadius: 32,
      maxWidth: '480px',
      height: screenDimensions.width < 300 ? '100vh' : null,
      width: screenDimensions.width < 300 ? '100vw' : null,
      overflowY: 'auto'
    },
    overlay: {
      backgroundColor: 'rgba(51, 51, 51, 0.7)',
      zIndex: 100000
    }
  });
};


const stylesUpsellModal = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderWidth: 0,
    borderRadius: 10,
    maxWidth: 'calc(316px + 64px)',
    backgroundColor: '#fff', //cool-grey-80
    padding: 0,
  },
  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.7)',
    zIndex: 100000
  }
};

const stylesChangeRestaurant = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderWidth: 0,
    borderRadius: 10,
  },
  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.7)',
    zIndex: 100000
  }
};

const stylesWelcome = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderWidth: 0,
    borderRadius: 10,
  },
  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.7)',
    zIndex: 100000
  }
};

const stylesChoice = {
  content: {
    borderWidth: 0,
    borderRadius: 30,
    width: '448px',
    minWidth: '300px',
    maxWidth: 'calc(100vw - 3rem)',
    backgroundColor: 'white'
  },
  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.7)',
    zIndex: 100000
  }
};

const stylesNotification = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    display: 'grid',
    placeItems: 'center',
    borderWidth: 0,
    borderRadius: 10,
    padding: 0
  },
  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.7)',
    zIndex: 100000
  }
};

const stylesLoyaltyModal = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderWidth: 0,
    borderRadius: 16,
    overflow: 'visible',
    padding: 0,
    backgroundColor: 'transparent'
  },
  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.7)',
    zIndex: 100000
  }
};

const stylesLoyaltyModalMobile = {
  content: {
    top: 'auto',
    left: '0',
    right: '0',
    bottom: '0px',
    borderWidth: 0,
    borderRadius: 16,
    overflow: 'visible',
    padding: 0,
    backgroundColor: 'transparent',
    width: '100%'
  },
  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.7)',
    zIndex: 100000
  }
};

const stylesTermsAndConditionsModal = (screenWidth) => ({
  content: {
    left: '50%',
    transform: screenWidth < 555 ? 'translate(-50%, 0%)' : 'translate(-50%, 50%)',
    borderWidth: 0,
    borderRadius: 12,
    padding: 0,
    overflow: 'hidden',
    bottom: screenWidth < 555 ? '0' : '50%',
    height: screenWidth < 555 ? 'auto' : '454px',
    width: screenWidth < 590 ? '100%' : '590px',
    top: screenWidth < 555 ? '10vh' : 'auto',
    backgroundColor: 'transparent'
  },
  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.7)',
    zIndex: 100000
  }
});
