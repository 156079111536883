import { useEffect, useRef } from 'react';

export const useMostVisibleElement = (
  elements,
  onElementMostVisibleChange,
  areRefsPopulated = true,
  refs,
  isTabletScreen,
  isBackButtonOverlapping,
  inApp
) => {
  const observerRef = useRef(null);
  const observedEntriesRef = useRef([]);
  const navHeight = refs.current.navRef?.clientHeight ?? 100;
  const headerHeight = refs.current.headerRef?.clientHeight ?? 172;
  const backButtonHeight = inApp ? 0 : (isBackButtonOverlapping ? refs.current.backButtonRef?.children[0]?.clientHeight || 64 : 0);

  let topVerticalOffset = navHeight + backButtonHeight + (isTabletScreen ? 0 : headerHeight + 20);

  // Set default bottom vertical offset based on screen type
  let bottomVerticalOffset = 0;

  // Set bottomVerticalOffset to a % of window height only if it's greater than 0
  for (let scale = 0.8; scale >= 0.5; scale -= 0.1) {
    if (window.innerHeight - topVerticalOffset - (window.innerHeight * scale) > 0) {
      bottomVerticalOffset = window.innerHeight * scale;
      break;
    }
  }


  // Ensure window.innerHeight - topVerticalOffset - bottomVerticalOffset > 0 for both cases
  let remainingHeight = window.innerHeight - topVerticalOffset - bottomVerticalOffset;

  if (remainingHeight <= 0) {
      topVerticalOffset = Math.max(0, topVerticalOffset + remainingHeight - 1);

      // Recalculate remaining height after adjusting topVerticalOffset
      remainingHeight = window.innerHeight - topVerticalOffset - bottomVerticalOffset;

      if (remainingHeight <= 0) {
          bottomVerticalOffset += remainingHeight; // Adjust bottomVerticalOffset if needed
      }
  }

  useEffect(() => {
    if (!areRefsPopulated || !elements || !elements.length) return;

    const options = {
      rootMargin: `-${topVerticalOffset}px 0px -${bottomVerticalOffset}px 0px`,
      threshold: Array.from({ length: 1001 }, (_, i) => i / 1000)
    };
    observerRef.current = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        const existingEntryIndex = observedEntriesRef.current.findIndex(observedEntry => observedEntry.target === entry.target);
        if (existingEntryIndex !== -1) {
          observedEntriesRef.current[existingEntryIndex] = entry;
        } else {
          observedEntriesRef.current.push(entry);
        }
      });

      let mostVisibleElement = null;
      let maxVisibleHeight = 0;
      observedEntriesRef.current.forEach(entry => {
        const visibleHeight = entry.intersectionRect.height;
        if (visibleHeight > maxVisibleHeight) {
          maxVisibleHeight = visibleHeight;
          mostVisibleElement = entry.target;
        }
      });
      if (mostVisibleElement) {
        const index = elements.findIndex((el) => el === mostVisibleElement);
        onElementMostVisibleChange(index);
      }
    }, options);

    elements.forEach((el) => {
      if (el) observerRef.current.observe(el);
    });

    return () => {
      if (observerRef.current) observerRef.current.disconnect();
      observedEntriesRef.current = [];
    };
  }, [elements, onElementMostVisibleChange, areRefsPopulated]);
};
