import React, { useEffect, useState, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const RewardCarousel = ({ children, noRewards=false }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [carouselItems, setCarouselItems] = useState([]);
  const [carouselWidth, setCarouselWidth] = useState(0);
  const [carouselScroll, setCarouselScroll] = useState(0);

  const screenDimensions = useWindowDimensions();
  const wrapperFlexBreakpoint = 1024;

  const carousel = useRef();
  const carouselWrapper = useRef();

  const updateIndex = (indexvalue) => {
    const setIndex = indexvalue;
    if (carouselItems.length > setIndex) {
      setActiveIndex(setIndex);
    }
  };

  useEffect(() => {
    if (carousel.current) {
      setCarouselWidth(carousel.current.clientWidth);
    }
  }, [carousel, screenDimensions]);

  useEffect(() => {
    setCarouselScroll(0);
    updateIndex(0);
  }, [screenDimensions]);

  useEffect(() => {
    if (carouselWrapper.current) {
      var childCards = [].slice.call(carouselWrapper.current.children);
      setCarouselItems(childCards);
    }
  }, [carouselWrapper]);

  const scrollPostion = (event) => {
    const direction = event.dir.toLowerCase();
    if (carouselWidth < wrapperFlexBreakpoint) {
      if (direction === 'left') {
        setCarouselScroll(carouselItems[activeIndex + 1]?.clientWidth + 16);
        updateIndex(activeIndex + 1);
      } else if (direction === 'right') {
        if(activeIndex - 1 >= 0) {
          setCarouselScroll(carouselItems[activeIndex - 1]?.clientWidth + 16);
          updateIndex(activeIndex - 1);
        }
      }
    }
  };

  const handlers = useSwipeable({
    onSwiped: (eventData) => scrollPostion(eventData),
    preventScrollOnSwipe: false,
    trackMouse: true,
  });

  return (
    <div
      className="container is-max-widescreen"
      ref={carousel}
    >
      <section
        {...handlers}
        className="section is-small is-padless-sides-mobile is-clipped carousel club-rewards"
        style={{ paddingTop: noRewards ? 0 : 4}}
      >
        <div
          ref={carouselWrapper}
          className={`inner ${
            screenDimensions.width > wrapperFlexBreakpoint
              ? 'is-flex is-flex-wrap-wrap'
              : 'is-inline-flex ml-4'
          }`}
          style={{
            transform: `translateX(-${activeIndex * carouselScroll + 'px'})`,
            gap: 16
          }}
        >
          {children}
        </div>
      </section>
    </div>
  );
};

export default RewardCarousel;
