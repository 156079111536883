import React from 'react';
import checkboxTicked from '../../../img/icons/checked.svg';
import checkboxEmpty from '../../../img/icons/checkboxempty.svg';

const CustomiseCardRequiredRadio = (props) => {
  const {
    selected,
    parent
  } = props;

  const isRadio = parent &&
    (parent.min === 1 && parent.max <= parent.min) ||
    (parent.minItems === 1 && parent.maxItems <= parent.minItems);

  return (
    <>
      {
        (isRadio) &&
        <>
          {
            (selected) ?
              <img src={checkboxTicked} data-testid='option-ticked' className="icon is-medium" />
              :
              <img src={checkboxEmpty} data-testid='option-unticked' className="icon is-medium" role='icon' />
          }

        </>
      }
    </>
  );
};

export default CustomiseCardRequiredRadio;