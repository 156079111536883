import React from 'react';
import { useState } from 'react';
import plus from '../img/icons/plus.svg';
import error from '../img/icons/closeError.svg';
import errorIcon from '../img/icons/error.svg';
import { useAddVoucherMutation, useRemoveVoucherMutation } from '../services/basket.api';
import { useDispatch, useSelector } from 'react-redux';
import { pathMW } from '../helpers/routingHelpers';
import { setCheckoutCompletePath } from '../store/sessionSlice';
import { Link } from 'react-router-dom';
import { freshRelevance } from '../helpers/commonHelpers';
import info from '../img/icons/iconInfo.svg'

const VoucherRedeem = ({ title, backgroundColor = 'transparent', rewardsApplied, inApp }) => {
  const basket = useSelector(state => state.basket);
  const { customer } = useSelector(state => state.session);
  const [voucherCodeInput, setVoucherCodeInput] = useState('');
  const [isError, setIsError] = useState(false);
  const [voucherNotLinked, setVoucherNotLinked] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [addVoucher] = useAddVoucherMutation();
  const [removeVoucher] = useRemoveVoucherMutation();
  const path = window.location.pathname;
  const dispatch = useDispatch();

  const formatVoucherInput = (e) => {
    setVoucherCodeInput(e.target.value.trim().toUpperCase());
  };

  const handleAddVoucher = async () => {
    const value = voucherCodeInput;
    if (isError) {
      setVoucherCodeInput('');
      setIsError(false);
    } else if (value.length > 0) {
      const { data } = await addVoucher({ voucherCode: value });
      if (data?.voucherExceptions?.length > 0) {
        setIsError(true);
        setVoucherNotLinked(data.voucherExceptions[0]?.voucherNotLinked);
        setErrorMessage(data.voucherExceptions[0]?.message);
      } else {
        freshRelevance('pageChange', null, { 'sendBeacon': true });
        setVoucherCodeInput('');
        setIsError(false);
      }
    }
  };

  const handleRemove = async (voucher) => {
    await removeVoucher({ voucherCode: voucher });
  };

  return (
    <div className="column is-12 p-0">
      <div className="columns is-flex is-flex-direction-column">
        {title && (
          <div className="column is-12">
            <div className="content">
              <h2 className={'title voucher-redeem-title is-4 mb-0-tablet'}>{title}</h2>
            </div>
          </div>
        )}

        <div className="column is-12 pt-0">
          {!inApp
            &&
            <div className="control is-flex voucher-redeem-input" style={{ backgroundColor: isError ? 'rgba(210,46,45, 0.2)' : backgroundColor }}>
              <>
                <input
                  disabled={rewardsApplied?.length > 0}
                  className="input voucher-input"
                  type="text"
                  style={{ backgroundColor: 'transparent', outline: 'none', boxShadow: 'none' }}
                  placeholder="Enter voucher code"
                  onChange={(e) => formatVoucherInput(e)}
                  value={voucherCodeInput}
                />
                <button
                  disabled={rewardsApplied?.length > 0}
                  className="voucher-redeem-button"
                  style={{
                    cursor: rewardsApplied?.length > 0 ? 'not-allowed' : 'pointer'
                  }}
                  onClick={rewardsApplied?.length > 0 ? null : handleAddVoucher}>
                  <i>
                    {
                      isError ?
                        <img src={error} alt="error" />
                        :
                        <img src={plus} alt="add" />
                    }
                  </i>
                </button>
              </>
            </div>
          }
                  {inApp && basket?.voucherCodes.length > 0 &&
                      <div style={{ backgroundColor: '#FFE9AE', padding: '1rem 1.5rem', fontSize: 14, display: 'flex', gap: 12, maxWidth: 'unset', borderRadius: 8 }}>
              <img src={info} alt="" style={{height: 16, width: 16, boxSizing: 'unset', marginTop: 2}}/>
              <div> 
                Loyalty Rewards and Vouchers can&lsquo;t be used together. Please remove your voucher code to add your rewards. 
              </div>
            </div>
          }
          {rewardsApplied?.length > 0 && <div className="mt-2">
            <p>Voucher codes can not be applied with another offer.</p>
          </div>}
        </div>
        {isError &&
          <div className="level is-mobile is-family-code" style={{ alignItems: 'start', padding: '0 1rem' }}>
            <div className="level-item is-narrow is-shrinkable is-align-top">
              <i className="icon is-small" >
                <img id='InnerInputError' src={errorIcon} alt="error" />
              </i>
            </div>
            <div style={{ width: '80%' }} className="level-item is-growable  is-shrinkable is-justify-content-flex-start is-align-top">
              {
                errorMessage?.length > 0 && !voucherNotLinked ?
                  <p key={errorMessage} className={'error-message'}>{errorMessage}</p>
                  :
                  null
              }
              {
                voucherNotLinked && customer !== null &&
                <p className={'error-message'}>Promotion is not valid with account. Could you try another one please?</p>
              }
              {
                voucherNotLinked && customer == null &&
                <p>
                  Promotion is not valid without account. Please <Link to={pathMW('/checkout/login', basket.isDelivery)} onClick={(() => dispatch(setCheckoutCompletePath(path)))} id='logInLink'>Log in</Link> to use your Voucher.
                </p>
              }
            </div>
          </div>
        }
        {!!basket?.voucherCodes && basket?.voucherCodes.length > 0 && (
          <div className="column is-12 is-flex-direction-column" >
            {basket?.voucherCodes.map((v, i) => (
              <div key={i} className="is-12 is-flex is-flex-direction-row is-justify-content-space-between pr-3 pr-0-desktop dm-sans">
                <p
                  className='block is-9 px-3'
                  style={{ fontSize: 18 }}
                >
                  Your {v.name} <span style={{ fontWeight: '700' }} className="dm-sans">{v.code}</span> has been added to your order.
                </p>
                <small
                  className='block is-3 is-flex is-justify-content-flex-end dm-sans is-underlined is-pointer'
                  onClick={() => handleRemove(v.code)}
                  style={{ fontSize: 16 }}
                >
                  Remove
                </small>
              </div>
            ))}
          </div>
        )}
      </div >
    </div >
  );
};

export default VoucherRedeem;