import { configureStore, Tuple } from '@reduxjs/toolkit'
import { thunk } from 'redux-thunk'
import basketReducer from './basketSlice';
import menuReducer from './menuSlice';
import sessionReducer from './sessionSlice';
import loyaltyReducer from './loyaltySlice';
import api from '../services/api';

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    basket: basketReducer,
    menu: menuReducer,
    session: sessionReducer,
    loyalty: loyaltyReducer,
  },
  middleware: () => new Tuple(api.middleware, thunk),
  devTools: true
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;