import { createSlice } from '@reduxjs/toolkit';
import { applicationApi } from '../services/application.api';
import { customerApi } from '../services/customer.api';

const initialState = {
  rewards: [],
};

const rewardsToDictionary = (rewards) => {
  let dictionary = {};
  let keys = Object.keys(rewards);
  Object.values(rewards).forEach((value, i) => {
    value.forEach(reward => {
      dictionary[reward.thirdPartyRewardTypeId] = {
        ...reward,
        tierName: keys[i],
      };
    });
  });
  return dictionary;
};

const shapeRewards = loyalty => {
  if (loyalty?.customer?.currentTier?.rewards?.length && loyalty.rewards) {
    const rewardDictionary = rewardsToDictionary(loyalty.rewards);
    return loyalty.customer.currentTier.rewards
      .filter(customerReward => customerReward.thirdPartyRewardTypeId
        && rewardDictionary[customerReward.thirdPartyRewardTypeId])
      .map((customerReward) => ({
        ...rewardDictionary[customerReward.thirdPartyRewardTypeId],
        ...customerReward,
      })).sort((a, b) => {
        const aPriority = new Number(a.priority || a.rewardDetails.priority || 1);
        const bPriority = new Number(b.priority || b.rewardDetails.priority || 1);
        return aPriority - bPriority;
      });
  }

  return [];
};

const shapeOfferRewards = loyalty => {
  if (loyalty?.customer?.currentTier?.offerRewards?.length && loyalty.rewards) {
    const rewardDictionary = rewardsToDictionary(loyalty.rewards);
    return loyalty.customer.currentTier.offerRewards
      .filter(customerReward => customerReward.thirdPartyRewardTypeId
        && rewardDictionary[customerReward.thirdPartyRewardTypeId])
      .map((customerReward) => {
        return {
          ...rewardDictionary[customerReward.thirdPartyRewardTypeId],
          ...customerReward,
        };
      });
  }

  return [];
};

const loyaltySlice = createSlice({
  name: 'loyalty',
  initialState,
  reducers: {
    updateLoyaltyState(state) {
      state.rewards = { ...state.rewards };
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      applicationApi.endpoints.getApplicationState.matchFulfilled,
      (state, { payload }) => {
        if (payload.customer?.loyalty) {
          state.customer = payload.customer.loyalty.customer;
          state.rewards = shapeRewards(payload.customer?.loyalty);
          state.offerRewards = shapeOfferRewards(payload.customer?.loyalty);
        } else {
          return initialState;
        }
      });
    builder.addMatcher(
      customerApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        if (payload.loyalty) {
          state.customer = payload.loyalty.customer;
          state.rewards = shapeRewards(payload.loyalty);
          state.offerRewards = shapeOfferRewards(payload.loyalty);
        } else {
          return initialState;
        }
      });
    builder.addMatcher(
      customerApi.endpoints.validate2FA.matchFulfilled,
      (state, { payload }) => {
        if (payload.loyalty) {
          state.customer = payload.loyalty.customer;
          state.rewards = shapeRewards(payload.loyalty);
          state.offerRewards = shapeOfferRewards(payload.loyalty);
        } else {
          return initialState;
        }
      }
    );

    builder.addMatcher(
      customerApi.endpoints.logout.matchFulfilled,
      () => {
        return initialState;
      }
    );
  }
});

export const { updateLoyaltyState } = loyaltySlice.actions;
export default loyaltySlice.reducer;