export interface AppConfig {
    ReactAppEnvironment: string,
    BRAND: string,
    CURRENCY_SYMBOL: string,
    GTM_ID: string,
    VERSION: string,
    SESSION_COOKIE_NAME: string
}

const fetchConfigPromise = new Promise<AppConfig>(async (resolve) => {
    const data = await fetch('/api/applicationConfig/oop');
    const result = await data.json() as AppConfig;
    resolve(result);
});

const getConfig = () => fetchConfigPromise;
export default getConfig;