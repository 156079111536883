import DietaryIcons from '../../../components/DietaryIcons';
import { useSelector } from 'react-redux';
import CustomiseCardRequiredRadio from './CustomiseCardRequiredRadio';
import { Tags } from '../../../components/Tags';

const CustomiseCardBase = ({
  name,
  selected,
  cost,
  parent,
  option,
  isPeppers,
  disabled,
  calories,
  showCalories,
  currentTier
}) => {
  const { isTabletScreen } = useSelector(state => state.session);

  const getPrice = () => {
    return (
      <>
        {
          cost && !isPeppers ?
            <p className='customise-card-price' data-testid='customise-card-info-price' style={{ opacity: disabled ? 0.5 : 1 }}>{cost?.toFixed(2)}</p>
            :
            null
        }
        {
          option.upliftPrice &&
          <p className='customise-card-price' data-testid='customise-card-info-uplift-price' style={{ opacity: disabled ? 0.5 : 1 }}>{option.upliftPrice.toFixed(2)}</p>
        }
      </>
    );
  }

  return (
    <>
      <div className='customise-card-info-container'>
        <CustomiseCardRequiredRadio
          selected={selected}
          parent={parent} />
        <div className={'customise-card-info-and-tag'}>
          <div className='customise-card-info'>
            <p data-testid='customise-card-info-name' style={{ opacity: disabled ? 0.5 : 1 }}>{name}</p>
            {
              showCalories && calories ?
                <p role='calories' style={{ opacity: 0.8 }} className='customise-card-info-calories' data-testid='customise-card-info-calories'>
                  {`(${calories})`}
                </p>
                :
                null
            }
            {
              (option.product?.dietPreferences || option.dietPreferences) &&
              <DietaryIcons icons={option.product?.dietPreferences || option.dietPreferences} style={{ margin: 0 }} iconSize={20} />
            }
            {isTabletScreen &&
              getPrice()
            }
          </div>
          <Tags
            currentTier={currentTier}
            excludeChildTags={true}
            showLocked={false}
            item={option}
            wrapperClass={'all-tags-wrapper'}
            outOfStockDisabled
          />
        </div>
      </div>
      {!isTabletScreen &&
        getPrice()
      }
    </>
  );
};

export default CustomiseCardBase;