import React, { useCallback } from 'react';
import hot from '../../../img/icons/dietary/Spicy.svg';
import extraHot from '../../../img/icons/dietary/Extra-Spicy.svg';
import CustomiseCardBase from './CustomiseCardBase';
import CustomiseCardPepper from './CustomiseCardPepper';
import CustomiseCardExtra from './CustomiseCardExtra';

const CustomiseCard = ({
  name,
  cardSelect,
  handleIncrease,
  handleDecrease,
  selected,
  cost,
  parent,
  option,
  isPeppers,
  reachedMax,
  disabled,
  required,
  currentTier,
  showCalories
} ) => {
  const isRadio = parent && (parent.min === 1 && parent.max <= parent.min) || (parent.minItems === 1 && parent.maxItems <= parent.minItems);
  const calories = (option.product?.calories || option.calories);

  const handleCardSelect = useCallback(() => {
    if (isRadio && !disabled && !option.outOfStock) {
      cardSelect(option);
    }
  }, [option, parent, cardSelect, selected]);

  const hasPeppers = (uiTags) => {
    if (!uiTags) return false;
    return uiTags.includes('Spicy 1') || uiTags.includes('Spicy 2');
  };

  const optionHasPeppers = hasPeppers(option.uiTags);
  const isBase = required && !optionHasPeppers;

  return (
    <div className="customise-card-option" onClick={handleCardSelect} style={{ cursor: !isRadio || option.outOfStock ? '' : 'pointer' }}>
      {
        isBase &&
        <CustomiseCardBase
          name={name}
          cost={cost}
          parent={parent}
          option={option}
          selected={selected}
          disabled={disabled || option.outOfStock}
          calories={calories}
          showCalories={showCalories}
          currentTier={currentTier}
          isOutOfStock={option.outOfStock}
        />
      }
      {
        optionHasPeppers &&
        <CustomiseCardPepper
          name={name}
          parent={parent}
          option={option}
          selected={selected}
          disabled={disabled}
          calories={calories}
          showCalories={showCalories}
        />
      }
      {
        !required &&
        <CustomiseCardExtra
          name={name}
          cost={cost}
          parent={parent}
          option={option}
          selected={selected}
          isPeppers={isPeppers}
          reachedMax={reachedMax}
          handleIncrease={handleIncrease}
          handleDecrease={handleDecrease}
          disabled={disabled || option.outOfStock}
          calories={calories}
          showCalories={showCalories}
          isOutOfStock={option.outOfStock}
        />
      }
    </div>
  );
};

export default CustomiseCard;

export const getPeppers = (uiTags) => {
  if (!uiTags) return null;
  else if (uiTags?.includes('Spicy 1')) {
    return <div className="level-item">
      <img src={hot} data-testid='spicy-icon' className="icon" role='icon' />
    </div>;
  } else if (uiTags?.includes('Spicy 2')) {
    return <div className="level-item">
      <img src={extraHot} data-testid='extra-spicy-icon' className="icon" role='icon' />
    </div>;
  } else {
    return null;
  }
};