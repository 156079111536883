export const pathMW = (url, isDelivery) => isDelivery ? `/delivery${url}` : `/collection${url}`;

export const historyMW = {
  push: (url, isDelivery, navigate, state) => {
    validateCookiesForJourney(isDelivery ? 'Delivery' : 'ClickAndCollect');
    return isDelivery ? navigate(`/delivery${url}`, {state}) : navigate(`/collection${url}`, {state});
  },
  replace: (url, isDelivery, navigate, state) => {
    validateCookiesForJourney(isDelivery ? 'Delivery' : 'ClickAndCollect');
    return isDelivery ? navigate(`/delivery${url}`, {state}) : navigate(`/collection${url}`, {state});
  }
};

const validateCookiesForJourney = (journey) => {
  document.cookie = `JOURNEY = ${journey}; expires = Session`;
};