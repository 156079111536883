import { useSelector } from 'react-redux';
import { useScrollAnimationController } from '../hooks/useScrollAnimationController';

const PopupBanner = ({ refs, children, hideCheckout, isMenu, justifyEnd, parentRef, skipAnimation, isCustomise }) => {
  const { appInsets } = useSelector(state => state.session);

  const shouldAnimate = useScrollAnimationController(parentRef.current, refs?.current?.bannerRef, skipAnimation);

  return (
    <div
      ref={r => refs?.current ? refs.current.bannerRef = r : null}
      className={`popup-banner ${isMenu ? 'isMenu' : ''} ${isCustomise ? 'isCustomise' : ''}`}
      style={{
        paddingBottom: appInsets?.bottom ? appInsets.bottom : null,
        zIndex: 1001 // Make sure it stays infront of category header, login banner & footer
      }}
    >
      {!hideCheckout && (
        <div className={`popup-banner-container ${justifyEnd ? 'justify-end' : ''}`}>
          {children}
        </div>)}
    </div>
  );
};

export default PopupBanner;

