import { useEffect } from 'react';
import chevron from '../img/menu/back-btn-chevron.svg';
import ellipse from '../img/menu/back-btn-ellipse.svg';
import { useSelector } from 'react-redux';

const BackMenuButton = ({ handleBack, refs }) => {
  const isTabletScreen = useSelector(state => state.session.isTabletScreen);
  const topMargin = isTabletScreen
    ? (refs.current.navRef?.clientHeight ?? 0)
    : ((refs.current.navRef?.clientHeight ?? 0) + (refs.current.headerRef?.clientHeight ?? 0));

  useEffect(() => {
    const onScroll = () => {
      const isMenuContainerVisible = refs.current.menuContainerRef?.getBoundingClientRect().bottom - (refs.current.navRef?.clientHeight ?? 100) > 0;
      const isHeroSectionVisible = refs.current.heroBackButtonRef?.getBoundingClientRect().bottom - (refs.current.navRef?.clientHeight ?? 100) > 0;

      if (refs.current.backButtonRef) {
        if (isHeroSectionVisible || !isMenuContainerVisible) {
          refs.current.backButtonRef.style.display = 'none';
        } else {
          refs.current.backButtonRef.style.display = 'block';
        }
      }
    };
    window.addEventListener('scroll', onScroll, true);
    return () => {
      window.removeEventListener('scroll', onScroll, true);
    };
  }, [refs]);

  return (
    <div ref={r => refs.current.backButtonRef = r} style={{ display: 'none' }}>
      <div style={{ top: topMargin }} className='btn-menu-back-container'>
        <div className='btn-menu-back-innercontainer'>
          <button className={'btn-menu-back'} onClick={handleBack}>
            <div className="icon-wrapper">
              <img src={ellipse} className="ellipse" alt="Ellipse" />
              <img src={chevron} className="chevron" alt="Chevron" />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default BackMenuButton;
