import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useBreakPoint } from './../../hooks/useBreakPoint';
import useWindowDimensions from './../../hooks/useWindowDimensions';
import RewardCarousel from './RewardCarousel';
import RewardTicket from './RewardTicket';
import { LoyaltyModalWrapper } from './../../helpers/modalHelpers';
import chevronDown from '../../img/icons/chevronDown.svg';
import { restaurantInclusionExclusion } from '../../helpers/loyaltyHelpers';

const RewardController = () => {
  const { inApp } = useSelector(state => state.session);
  const rewards = useSelector(state => state.loyalty?.rewards);
  const offerRewards = useSelector(state => state.loyalty?.offerRewards);
  const showStackedRewards = useSelector(state => state.session?.features?.showStackedRewards);
  const { width } = useWindowDimensions();
  const isSmallScreen = useBreakPoint(width, 768);
  const currentTier = useSelector(state => state.loyalty?.customer?.currentTier?.name);
  const restaurant = useSelector(state => state.session.restaurant);
  const name = restaurant?.name || '';
  const id = restaurant?.id || 0;
  const [loyaltyModalOpen, setLoyaltyModalOpen] = useState(false);
  const [selectedReward, setSelectedReward] = useState({});
  const [openRewards, setOpenRewards] = useState(false);
  const handleClose = () => {
    setSelectedReward({});
    setLoyaltyModalOpen(false);
  };

  const offersToDisplay = useMemo(() => { 
    return offerRewards?.filter(offer => restaurantInclusionExclusion(offer?.restaurantRules, id)) ?? [];
  }, [offerRewards, id]);
  
  const rewardsToDisplay = useMemo(() => {
    return rewards?.filter(reward => restaurantInclusionExclusion(reward?.restaurantRules, id)) ?? [];
  }, [rewards, id]);


  const handleClickVoucher = ({ reward, loyaltyRewardImage, isOffer }) => {
    setSelectedReward({ reward, loyaltyRewardImage: loyaltyRewardImage, isOffer });
    setLoyaltyModalOpen(true);
  };

  return (
    <>
      <div className='rewards-container'>
        <div
          className='rewards-header'
          onClick={() => setOpenRewards(o => !o)}
        >
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10, width: '100%' }}>
            <h1 className='title reward-carousel-title'>REWARDS & OFFERS</h1>
          </div>
          <img
            alt="Expand"
            src={chevronDown}
            style={{
              width: 48,
              height: 24,
              transform: `rotate(${openRewards ? '180deg' : 0})`,
              paddingRight: openRewards ? 0 : 24,
              paddingLeft: openRewards ? 24 : 0,
            }}
          />
        </div>
        {/* values for margin bottom are set to add up with other spacing in the next section to get the correct spacing */}
        <div className='reward-carousel-divider' style={{ marginBottom: openRewards ? 16 : (isSmallScreen ? 6 : 0) }} />
        {openRewards &&
          <div className='collapsable-reward-carousel-wrapper'>
            <p style={{ fontSize: 12, marginBottom: '1rem' }}>Terms and Conditions apply, click tile for details.</p>
            <h1 className='title reward-carousel-section-title'>YOUR REWARDS</h1>
            {!!rewardsToDisplay.length && <p className='title reward-carousel-subtitle' style={{marginTop: 16}}>{'Your Rewards can be used in restaurants or when ordering online via PizzaExpress.'}</p>}
            <div className='reward-carousel-scroller' style={{ paddingTop:rewardsToDisplay.length ? 0 : 4 }}>
              {!rewardsToDisplay.length && <p className='title reward-carousel-subtitle'> There are no loyalty rewards available right now.</p>}
              {!!rewardsToDisplay.length && rewardsToDisplay.map((reward, i) => (
                <RewardTicket
                  key={`reward-${i}`}
                  colourOfTile={reward.rewardDetails.colourOfTile}
                  designOfTile={reward.rewardDetails.designOfTile}
                  handleModal={handleClickVoucher}
                  reward={reward}
                  index={i}
                  tierName={currentTier}
                />
              ))}
            </div>

            {!!showStackedRewards &&
              <>
                <h1 className='title reward-carousel-section-title'>YOUR OFFERS</h1>
                {!!offersToDisplay.length && <p className='title reward-carousel-subtitle'>These offers can&apos;t be used alongside your Rewards.</p>}
                <div className='reward-carousel-scroller' style={{ paddingTop: rewards.length ? 0 : 4 }}>
                  {!offersToDisplay.length && <p className='title reward-carousel-subtitle'>No offers available at {name}.</p>}
                  {!!offersToDisplay.length &&
                    offersToDisplay.map((offer, i) => (
                      <RewardTicket
                        key={`reward-${i}`}
                        colourOfTile={offer.rewardDetails.colourOfTile}
                        designOfTile={offer.rewardDetails.designOfTile}
                        handleModal={handleClickVoucher}
                        reward={offer}
                        index={i}
                        tierName={currentTier}
                        isOffer
                      />
                    ))
                  }
                </div>
                <div className='reward-carousel-divider' style={{ marginBottom: 0 }} />
              </>
            }
          </div>
        }
      </div>
      <LoyaltyModalWrapper
        show={loyaltyModalOpen}
        onClose={handleClose}
        currentTier={currentTier}
        reward={selectedReward.reward}
        loyaltyRewardImage={selectedReward.loyaltyRewardImage}
        inApp={inApp}
        isOffer={selectedReward?.isOffer}
      />
    </>
  );
};

export default RewardController;
