import React, { Fragment } from 'react';
import { useConfig } from '../../../helpers/useConfig';

const TotalSavings = ({ discounts, basketItems }) => {
  const config = useConfig();
  const renderedDiscounts = [];

  const renderDiscountRow = (discounts, basketItem, renderedDiscounts) => {
    return discounts.map((discount, index) => {
      if (!renderedDiscounts.includes(discount) &&
        (discount.id === basketItem.customID || discount.id === basketItem.id)) {
        renderedDiscounts.push(discount);
        return (
          <div className="is-flex is-justify-content-space-between" key={index}>
            <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '75%' }}>
              {discount.offerTitle.replace(/#/g, ' ')}
            </div>
            <div style={{ whiteSpace: 'nowrap', width: '25%', textAlign: 'right' }}>-{config.CURRENCY_SYMBOL + discount.amount.toFixed(2)}</div>
          </div >);
      }
    });
  };
  
  return (
    <>
      {basketItems.length > 0 && (
        <div style={{ backgroundColor: '#F7F5F4', marginBottom: 0 }} className="total-savings-container" >
          <div className="is-flex is-justify-content-space-between has-font-weight-semibold">
            <h5 className="mb-0 domain-display" style={{ fontSize: 20 }}>Basket &amp; Loyalty Savings</h5>
          </div>
          {basketItems.map((basketItem, i) => (
            <Fragment key={i}>
              <div
                className="is-flex is-justify-content-space-between pt-4"
                style={{ fontSize: 16 }}
                key={'reward-' + i}
              >
                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '60%' }}>{basketItem.name}</div>
                <div style={{ width: '15%', textAlign: 'end' }}>
                  {basketItem.quantity}
                </div>
                <div style={{ width: '25%', textAlign: 'right' }}>
                  {config.CURRENCY_SYMBOL + basketItem.calculatedCost.toFixed(2)}
                </div>
              </div>
              {renderDiscountRow(discounts, basketItem, renderedDiscounts)}
            </Fragment>
          ))}
        </div>
      )}
    </>
  );
};

export default TotalSavings;
