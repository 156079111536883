import DietaryIcons from '../../../components/DietaryIcons';
import { getPeppers } from './CustomiseCard';
import CustomiseCardRequiredRadio from './CustomiseCardRequiredRadio';

const CustomiseCardPepper = ({
  name,
  selected,
  parent,
  option,
  disabled,
  calories,
  showCalories
}) => {

  return (
    <div className='customise-card-info-container peppers-or-extras'>
      <div className='customise-card-info peppers'>
        <CustomiseCardRequiredRadio
          selected={selected}
          parent={parent}
        />
        <p data-testid='customise-card-info-name' style={{ opacity: disabled ? 0.5 : 1 }}>{name}</p>
        {
          showCalories && calories ?
            <p role='calories' data-testid='customise-card-info-calories' style={{ opacity: 0.8 }} className='customise-card-info-calories'>
              {`(+${calories})`}
            </p>
            :
            null
        }
        {
          (option.product?.dietPreferences || option.dietPreferences) &&
          <DietaryIcons icons={option.product?.dietPreferences || option.dietPreferences} style={{ margin: 0 }} iconSize={20} />
        }
      </div>
      {
        getPeppers(option.uiTags)
      }
    </div>
  );
};

export default CustomiseCardPepper;