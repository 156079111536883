import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useBreakPoint } from '../../../hooks/useBreakPoint';
import InvalidVoucher from '../../../components/modals/InvalidVoucher';
import { useConfig } from '../../../helpers/useConfig';
import { getCals } from '../../../helpers/basketHelpers';

import extraSpicyImg from '../../../img/icons/dietary/Extra-Spicy.svg';
import glutenFreeImg from '../../../img/icons/dietary/Gluten-Free.svg';
import spicyImg from '../../../img/icons/dietary/Spicy.svg';
import veganImg from '../../../img/icons/dietary/Vegan.svg';
import vegetarianImg from '../../../img/icons/dietary/Vegetarian.svg';

const imageLookup = {
  ['Extra-Spicy']: extraSpicyImg,
  ['Gluten-Free']: glutenFreeImg,
  ['Spicy']: spicyImg,
  ['Vegan']: veganImg,
  ['Vegetarian']: vegetarianImg,
};
import { getPeppers } from './CustomiseCard';
import { useSelector } from 'react-redux';

const CustomiseHeader = (props) => {
  const {
    quantity,
    name,
    description,
    customisedItem,
    screenWidth,
    isBundle,
    lastHeader,
    currentPrice,
    isOpen,
    item,
    showCalories
  } = props;
  const navigate = useNavigate();
  const isSmallScreen = useBreakPoint(screenWidth, 500);
  const cals = getCals(item, customisedItem?.subProducts?.[0]?.subProducts?.[0].id);
  const duoRomanaIds = useSelector(state => state.session.frontEndConfig?.duoRomanaIds);

  return (
    <>
      <div style={{ marginTop: lastHeader ? 16 : null, width: '100%' }}>
        <div className="is-flex-direction-column">
          <div className="content is-flex-direction-column" style={{ marginBottom: description ? 24 : 0, }}>
            <div className="block is-flex is-justify-content-space-between" style={{width: '100%', flexWrap: 'wrap'}}>
              <h2 className="customise-header-item-name" style={{ marginBottom: 0 }} >{name && name.replace(/NEW! /g, '').split('(')[0]}</h2>
              {!isBundle && customisedItem?.dietPreferences && !duoRomanaIds?.includes(customisedItem?.id) && (<div className="level mt-2 is-mobile is-justify-content-center is-pulled-right">
                {customisedItem?.dietPreferences.map((option, idx) => {
                  const icon = imageLookup[option];
                  if (!icon) return <></>;
                  return (
                    <div key={option + idx} className="level-item is-narrow is-shrinkable">
                      <div className={`icon ${isSmallScreen ? 'is-small' : ''}`}>
                        <img className="w-100 h-100" data-testid={'dietary-icon-' + option} src={icon} />
                      </div>
                    </div>
                  )
                })}
                {
                  getPeppers(item.uiTags)
                }
              </div>)}
            </div>
            <div className="block is-flex is-align-items-center" style={{ marginBottom: '32px' }} >
              <p className="customise-header-item-price" data-testid='customise-product-price' style={{ margin: '0 1rem 0 0' }}>{(currentPrice * quantity).toFixed(2)}</p>
              {
                customisedItem && !isBundle && cals && showCalories &&
                <span data-testid={'customise-product-calories'} style={{ opacity: 0.8, fontSize: 18 }}>{`(${cals})`}</span>
              }
            </div>
            <div className={`is-flex is-justify-content-space-between ${isSmallScreen && 'is-flex-direction-column'} is-flex-wrap-wrap`} style={{ gap: isSmallScreen ? 32 : 24, marginBottom: 0 }}>
              <a className="is-flex is-align-items-center customise-header-change-selection dm-sans" style={{ marginBottom: 0 }} onClick={() => navigate(-1)}>Change selection</a>
            </div>
          </div>

          {description && (
            <div className="block">
              <div className="content">
                <p style={{ fontSize: 16 }}>{description}</p>
              </div>
            </div>

          )}
        </div>

        <InvalidVoucher isOpen={isOpen} />
      </div>
    </>
  );
};

export default CustomiseHeader;