import { MenuCategory } from '../types/MenuTypes';
import { Restaurant, RestaurantSearchResultList } from '../types/RestaurantTypes';
import api from './api';

export const restaurantApi = api.injectEndpoints({
  endpoints: (builder) => ({
    findRestaurantById: builder.query<Restaurant, string>({
      query: (id) => `restaurant/GetById/${id}`,
    }),
    getRestaurantByName: builder.query<RestaurantSearchResultList, any>({
      query: ({ postCode, isDelivery }) => `restaurant/LookupName/${postCode}/${isDelivery}`,
    }),
    getRestaurantLatLong: builder.query<RestaurantSearchResultList, any>({
      query: ({ latitude, longitude, isDelivery }) => `restaurant/LookupLatLong/${latitude}/${longitude}/${isDelivery}`,
    }),
    getMenuCategory: builder.query<MenuCategory, { id: string, name: string}>({
      query: ({ id, name }) => `restaurant/GetMenuById/${id}/${name}`,
      providesTags: (result) =>
        result
          ? [{ type: 'MenuCategory' as const, id: result.name }]
          : []
    }),
    getSingleMenuItem: builder.query({
      query: ({ id, itemId }) => `restaurant/getSingleMenuItem/${id}/${itemId}`,
    }),
    getBundles: builder.query({
      query: () => 'restaurant/getbundles',
    }),
    getTimeSlots: builder.query<string[], string>({
      query: (id) => `restaurant/getTimeSlots/${id}`,
    }),
    getMenuForCategories: builder.query<MenuCategory[], { id: string, categoryNames: string[]}>({
      query: ({ id, categoryNames }) => {
        const categoryNamesParam = categoryNames.map((name: string) => `categoryNames=${encodeURIComponent(name)}`).join('&');
        return `restaurant/GetMenuForCategories?restaurantId=${id}&${categoryNamesParam}`;
      },
      providesTags: (result) =>
        result
          ? [...result.map(({ name }) => ({ type: 'MenuCategory' as const, id: name }))]
          : []
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetMenuCategoryQuery,
  useLazyFindRestaurantByIdQuery,
  useLazyGetRestaurantByNameQuery,
  useLazyGetRestaurantLatLongQuery,
  useGetBundlesQuery,
  useLazyGetTimeSlotsQuery,
  useGetSingleMenuItemQuery,
  useGetMenuForCategoriesQuery
} = restaurantApi;