import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useScrollAnimationController = (
  pageRef,
  bannerRef,
  skipAnimation,
  otherDependencies = [] // Watch for state changes that could effect scroll position
) => {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const {inApp} = useSelector(state => state.session);
  useEffect(() => {
    if(!pageRef || !bannerRef || skipAnimation || !pageRef.getBoundingClientRect) return;
    const {top, height} = pageRef.getBoundingClientRect();
    const {top: elementTop, height: bannerHeight} = bannerRef.getBoundingClientRect();
    const offset = inApp ? 10 : 0;
    setShouldAnimate(top + height < elementTop + bannerHeight + offset);
  }, [scrollPosition, pageRef, bannerRef, ...otherDependencies]);

  useEffect(() => {
    const onScroll = () => setScrollPosition(document.body.scrollTop || window.scrollY);
    window.addEventListener('scroll', onScroll, true);
    return () => {
      window.removeEventListener('scroll', onScroll, true);
    };
  }, []);

  return shouldAnimate;
};