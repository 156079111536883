import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getTags } from "../helpers/basketHelpers";

const initialState = {
  loyaltyTags: [],
  birthdayTags: [],
  uiTags: [],
  hasTags: false,
  lockedBDayTags: [],
  lockedLoyaltyTags: []
};

const getTagsState = (prevState, item, currentTier, showLocked, excludeChildTags, isBirthDayRewardLocked, showLowerTiers) => {
  const visibleUiTags = item?.uiTags?.filter(t => !t.includes("Spicy"));
  
  if (!currentTier) {
    return { ...prevState, uiTags: visibleUiTags ?? [], hasTags: !!visibleUiTags?.length };
  }

  const customerTierText = currentTier.toLowerCase();
  const loyaltyTags = getTags(item, 'loyaltyTags', excludeChildTags)
  const birthdayTags = getTags(item, 'birthdayTags', excludeChildTags);
  if (showLocked) {
    const { tags: loyaltyUnlocked, lockedTags: loyaltyLocked } = sortLockedTags(loyaltyTags, customerTierText);
    const { tags: birthdayUnlocked, lockedTags: birthdayLocked } = sortLockedBirthdayTags(birthdayTags, customerTierText, isBirthDayRewardLocked, showLowerTiers);
    return {
      loyaltyTags: loyaltyUnlocked,
      birthdayTags: birthdayUnlocked,
      uiTags: visibleUiTags ?? [],
      hasTags: loyaltyTags?.length || birthdayTags?.length || visibleUiTags?.length || loyaltyLocked?.length || birthdayLocked?.length,
      lockedBDayTags: birthdayLocked,
      lockedLoyaltyTags: loyaltyLocked
    };
  }
  else {
    const visibleLoyaltyTags = loyaltyTags?.filter(t => checkShouldDisplayLoyaltyTag(customerTierText, t)).map(tag => tag.toLowerCase() === 'signup' ? 'Welcome' : tag);
    const visibleBirthdayTags = birthdayTags?.filter(t => checkShouldDisplayBirthdayTag(customerTierText, t, isBirthDayRewardLocked, showLowerTiers)).map(tag => tag.toLowerCase() === 'signup' ? 'Welcome' : tag);
    return {
      ...prevState,
      loyaltyTags: visibleLoyaltyTags ?? [],
      birthdayTags: visibleBirthdayTags ?? [],
      uiTags: visibleUiTags ?? [],
      hasTags: visibleBirthdayTags?.length || visibleLoyaltyTags?.length || item?.uiTags?.length
    };
  }
}

export const useItemTags = (item, currentTier, showLocked = false, excludeChildTags = false, showLowerTiers = false) => {
  const isBirthDayRewardLocked = useSelector(state => state.session?.customer?.isBirthDayRewardLocked);

  const [allTags, setAllTags] = useState(getTagsState(initialState, item, currentTier, showLocked, excludeChildTags, isBirthDayRewardLocked, showLowerTiers));

  useEffect(() => {
    if (!item) return;
    setAllTags(state => getTagsState(state, item, currentTier, showLocked, excludeChildTags, isBirthDayRewardLocked, showLowerTiers));
  }, [item, currentTier])

  return allTags;
}

const TierEnum = {
  signup: 0,
  bronze: 1,
  silver: 2,
  gold: 3
};

const checkShouldDisplayBirthdayTag = (customerTier, itemTier, isBirthDayRewardLocked, showLowerTiers) => {
  return !isBirthDayRewardLocked && (showLowerTiers ? TierEnum[customerTier] <= TierEnum[itemTier] : customerTier === itemTier.toLowerCase());
}

const checkShouldDisplayLoyaltyTag = (customerTier, itemTier) => customerTier === itemTier.toLowerCase();

const isTagLocked = (customerTier, itemTier) => {
  if (!customerTier || !itemTier) return false;
  if (TierEnum[customerTier] >= TierEnum[itemTier]) return false;
  else return true;
}

const isTagBelowCurrentTier = (customerTier, itemTier) => {
  if (!customerTier || !itemTier) return true;
  if (TierEnum[customerTier] > TierEnum[itemTier]) return true;
  else return false;
}

const sortLockedTags = (tags, currentTier, showLowerTiers) => {
  if (!tags?.length) return { tags: [], lockedTags: [] };
  return tags.reduce((acc, cur) => {
    const lowerCaseTag = cur.toLowerCase();
    const friendlyTag = lowerCaseTag === 'signup' ? 'Welcome' : cur;
    if (!showLowerTiers && isTagBelowCurrentTier(currentTier, lowerCaseTag)) return acc;
    if (isTagLocked(currentTier, lowerCaseTag)) acc.lockedTags.push(friendlyTag);
    else acc.tags.push(friendlyTag);
    return acc;
  }, { tags: [], lockedTags: [] })
}

const sortLockedBirthdayTags = (tags, currentTier, isBirthDayRewardLocked, showLowerTiers = false) => {
  if (!tags?.length) return { tags: [], lockedTags: [] };
  return tags.reduce((acc, cur) => {
    const lowerCaseTag = cur.toLowerCase();
    const friendlyTag = lowerCaseTag === 'signup' ? 'Welcome' : cur;
    if (!showLowerTiers && isTagBelowCurrentTier(currentTier, lowerCaseTag)) return acc;
    if (isTagLocked(currentTier, lowerCaseTag) || isBirthDayRewardLocked) acc.lockedTags.push(friendlyTag);
    else acc.tags.push(friendlyTag);
    return acc;
  }, { tags: [], lockedTags: [] })
}