export const calcBundleCost = (bundle) => {
  const total = bundle.sections.reduce((acc, cur) => {
    let sectionUplift = cur.products.reduce((acc, cur) => {
      if (cur.upliftPrice) {
        return acc += cur.upliftPrice * cur.product.quantity;
      } else return acc;
    }, 0);
    return acc += sectionUplift;
  }, bundle.basePrice);
  return total;
};

export const dereff = (item) => {
  if (Array.isArray(item)) {
    return [...item.map(el => dereff(el))];
  } else if (typeof item === 'object' && item !== null) {
    let newObj = {};
    Object.keys(item).forEach(key => {
      newObj[key] = dereff(item[key]);
    });
    return { ...newObj };
  } else {
    return item;
  }
};

export const removeOptions = (bundle) => {
  let newBundle = dereff(bundle);
  if (newBundle?.sections.length)
    newBundle.sections.forEach(el => {
      el.products = [];
    });
  return newBundle;
};

export const findProduct = (fn, product, customisedBundle, sectionId, maxItems) => {
  const newCustomBundle = dereff(customisedBundle);
  const section = newCustomBundle.sections.find(el => el.id === sectionId);
  const exists = section.products.findIndex(el => `${getBaseName(el)}${el.product.name}` === `${getBaseName(product)}${product.product.name}`);
  const itemNumber = GetItemNumber(section);

  return fn(product, newCustomBundle, exists, section, itemNumber, maxItems);
};

export const andAdd = (product, newCustomBundle, exists, section, itemNumber, maxItems) => {
  if (exists > -1) {
    return newCustomBundle;
  } else if (exists === -1 && itemNumber === maxItems) {
    section.products.shift();
    section.products.push(addQuantity(product));
  } else if (exists === -1 && itemNumber < maxItems) {
    section.products.push(addQuantity(product));
  }
  return newCustomBundle;
};

export const andIncrease = (product, newCustomBundle, exists, section, itemNumber, maxItems) => {
  if (itemNumber === maxItems) return newCustomBundle;
  if (exists > -1) {
    section.products[exists].product.quantity++;
  } else if (exists === -1) {
    section.products.push(addQuantity(product));
  }
  return newCustomBundle;
};

export const andDecrease = (_, newCustomBundle, exists, section, itemNumber) => {
  const existingProduct = section.products[exists].product;
  if (itemNumber === 0 || exists === -1) return newCustomBundle;
  if (existingProduct.quantity > 1) {
    existingProduct.quantity--;
  } else if (existingProduct.quantity === 1) {
    section.products.splice(exists, 1);
  }
  return newCustomBundle;
};

export const removeDisabledItem = (itemIds, customisedBundle) => {
  const newCustomBundle = dereff(customisedBundle);
  newCustomBundle.sections.forEach(s => {
    const idx = s.products.findIndex(p => itemIds.includes(p.product.id));
    if (idx >= 0) {
      s.products.splice(idx, 1);
    }
  });
  return newCustomBundle;
};

export const addQuantity = (product) => {
  const subProduct = fixSubProductQuantity(product.product); //{ ...product.product, quantity: 1 };
  return { ...product, product: subProduct };
};

export const fixSubProductQuantity = (product) => {
  if (product.isGroup) {
    return { ...product, subProducts: product.subProducts.map(fixSubProductQuantity) };
  }
  if (!product.isGroup && (!product.quantity || product.quantity === 0)) {
    return { ...product, subProducts: product.subProducts.map(fixSubProductQuantity), quantity: 1 };
  }
};

export const GetItemNumber = (section) => {
  return section.products.reduce((acc, cur) => {
    return acc + cur.product.quantity;
  }, 0);
};

export const filterAvailableBundles = (bundles, categoryName) => {
  return bundles?.filter(b => b.category && b.category?.toLowerCase() === categoryName?.toLowerCase()) ?? [];
};

export const getBaseName = (option) => {
  if (option?.product) {
    const portion = option.product.subProducts[0].subProducts[0];
    if (portion?.id === '26' || portion?.id === '27') {
      return portion.name;
    } else return false;
  }
};

export const bundleDisplayIdxComparer = (a, b) => {
  if (a.displayIdx < b.displayIdx) {
    return -1;
  }
  if (a.displayIdx > b.displayIdx) {
    return 1;
  }
  return 0;
};

export const isBundleInStock = (bundle, outOfStockItems) => {
  return bundle.sections.every(s => s.products.some(p => !outOfStockItems.includes(p.product.id)));
};