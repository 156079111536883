import React, { useEffect, useRef } from "react";
import { createPortal } from 'react-dom';

export const MWModal = ({ isOpen, onClose, children, style, backgroundStyle, className, lockScroll = true }) => {
    const backgroundRef = useRef();

    useEffect(() => {
        const handleClick = (e) => {
            e.stopPropagation();
            e.preventDefault();
            if (e.target !== backgroundRef?.current) return;
            if (onClose) onClose();
        }

        const handleLockSroll = (e) => {
            const elementArr = document.getElementsByClassName('modal-content-wrapper');
            if (elementArr.length === 0) {
                return;
            }
            const modalContent = elementArr[0];

            // Only scroll when the modal dialog is overflowing and has a scroll bar
            if (e.target === backgroundRef?.current || !isOverflownAndCanScroll(modalContent)) {
                e.preventDefault();
                return false;
            }
        };

        if (isOpen) window.addEventListener('click', handleClick);

        if (lockScroll && isOpen) window.addEventListener('mousewheel', handleLockSroll, {
            passive: false
        });

        return () => {
            if (lockScroll) window.removeEventListener('mousewheel', handleLockSroll);

            window.removeEventListener('click', handleClick);
        }

    }, [isOpen]);

    const isOverflownAndCanScroll = (element) => {
        const computedStyle = window.getComputedStyle(element);
        const overflowY = computedStyle.overflowY;
        // Check if overflow-y allows scrolling
        const scrollEnabled = overflowY === 'scroll' || overflowY === 'auto';

        return (element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth) && scrollEnabled;
    }

    if (isOpen) {
        return createPortal(<div
            className={`${className} modal-overlay`}
            ref={backgroundRef}
            style={{
                width: '100vw',
                height: '100vh',
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: '1000000000000000',
                backgroundColor: 'rgba(0,0,0,0.4)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                ...backgroundStyle
            }}>
            <div className="modal-content-wrapper" style={{ ...style?.content }}>
                {children}
            </div>
        </div>,
            document.body)
    }
    return null;
}