import { memo, useEffect } from 'react';
import { Route, useNavigate, Routes } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/storeTypes';
import { freshRelevance, gtmEvent } from '../../helpers/commonHelpers';
import { setActiveCategory } from '../../store/menuSlice';
import { historyMW } from '../../helpers/routingHelpers';
import Menu from './pages/Menu';
import Bundle from './pages/Bundle';
import Customise from './pages/Customise';

const MenuController = memo(({ refs, onAddItem, onRemoveItem, notification, setNotification, refreshBasket}) => {
  const basket = useAppSelector(state => state.basket);
  const inApp = useAppSelector(state => state.session.inApp);
  const categories = useAppSelector(state => state.menu.categories);
  const filters = useAppSelector(state => state.menu.filters);
  const bundles = useAppSelector(state => state.menu.bundles);
  const activeCategory = useAppSelector(state => state.menu.activeCategory);
  const restaurantId = useAppSelector(state => state.menu.restaurantId);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    gtmEvent(basket.isDelivery ? 'delivery_view_menu' : 'collection_menu');
  }, [inApp]);


  const setCategory = (category) => {
    if (category != activeCategory) {
      freshRelevance('pageChange', null, { 'sendBeacon': true });
      dispatch(setActiveCategory(category));
    }
  };

  window.getListedBundles = () => ({ categoryBundles: bundles.filter(bundle => bundle.category === activeCategory), categoryItems: categories?.[activeCategory] });

  return (
    <>
      <Routes>
        <Route exact path="/*" element={
          <Menu
            refs={refs}
            basket={basket}
            bundles={bundles}
            filters={filters}
            categories={categories}
            selectedCategory={activeCategory}
            setSelectedCategory={setCategory}
            addItem={onAddItem}
            removeItem={onRemoveItem}
            navigateTo={(path, state) => historyMW.push(path, basket.isDelivery, navigate, state)}
            notification={notification}
            setNotification={setNotification}
            refreshBasket={refreshBasket}
            inApp={inApp}
          />
        } />
      </Routes>
      <Routes>
        <Route path="/bundle/:itemId" element={
          <Bundle refs={refs} restaurantId={restaurantId} filters={filters} refreshBasket={refreshBasket} />
        } />
        <Route path="/customise/:itemId" element={
          <Customise refs={refs} restaurantId={restaurantId} refreshBasket={refreshBasket} setNotification={setNotification} />
        } />
      </Routes >
    </>
  );
});

MenuController.displayName = 'MenuController'
export default MenuController;