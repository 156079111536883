import { Tags } from '../../../components/Tags';
import DietaryIcons from '../../../components/DietaryIcons';
import minimize from '../../../img/icons/minus-outlined.svg';
import plus from '../../../img/icons/plus-outlined.svg';

const CustomiseCardExtra = (props) => {
  const {
    name,
    selected,
    parent,
    option,
    disabled,
    cost,
    isPeppers,
    reachedMax,
    handleIncrease,
    handleDecrease,
    calories,
    showCalories,
    isOutOfStock
  } = props;
  const isRadio = parent && (parent.min === 1 && parent.max <= parent.min) || (parent.minItems === 1 && parent.maxItems <= parent.minItems);

  const getPrice = () => {
    return (
      <>
        {
          cost && !isPeppers ?
            <p className='customise-card-price' data-testid='customise-card-info-price' style={{ opacity: disabled ? 0.5 : 1 }}>{cost?.toFixed(2)}</p>
            :
            null
        }
        {
          option.upliftPrice &&
          <p className='customise-card-price' data-testid='customise-card-info-uplift-price' style={{ opacity: disabled ? 0.5 : 1 }}>{option.upliftPrice.toFixed(2)}</p>
        }
      </>
    );
  }

  const canChangeQuantity = !reachedMax && !disabled && (!(selected && cost === 0 && selected.quantity >= 3));

  return (
    <div className='customise-card-info-container peppers-or-extras'>
      <div style={{display: 'flex', flexDirection: 'column', gap: 8}}>

        <div className='customise-card-info'>
          <p data-testid='customise-card-info-name' style={{ opacity: disabled ? 0.5 : 1 }}>{name}</p>
          <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
            {
              showCalories && calories ?
                <p
                  role='calories'
                  data-testid='customise-card-info-calories'
                  style={{ opacity: 0.8 }}
                  className='customise-card-info-calories extras'
                >
                  {`(+${calories})`}
                </p>
                :
                null
            }
            {
              (option.product?.dietPreferences || option.dietPreferences) &&
              <DietaryIcons icons={option.product?.dietPreferences || option.dietPreferences} style={{ margin: 0 }} iconSize={20} />
            }
          </div>
          {
            getPrice()
          }
        </div>
        <Tags
          wrapperClass={'customise-card-tag-wrapper'}
          outOfStockOverride={isOutOfStock}
        />
      </div>
      {!isRadio &&
        <div className='customise-card-amount-container'>
          {
            selected && selected.quantity > 0 &&
            <>
              <img
                src={minimize}
                data-testid='minus'
                className="icon is-medium"
                role='icon'
                onClick={handleDecrease}
                style={{ cursor: 'pointer' }}
              />
              <p className="customise-card-amount-quantity">{selected.quantity}</p>
            </>
          }
          <img
            src={plus}
            className="icon is-medium"
            data-testid='plus'
            role='icon'
            style={{
              opacity: canChangeQuantity ? 1 : 0.5,
              cursor: canChangeQuantity ? 'pointer' : null
            }}
            onClick={!canChangeQuantity ? null : handleIncrease}
          />
        </div>
      }
    </div>
  );
};

export default CustomiseCardExtra;