import React from 'react';
import { useMatch } from 'react-router-dom';
import { useClearBasketMutation } from '../../services/basket.api';
import { useDispatch } from 'react-redux';
import { resetSessionSearchData } from '../../store/sessionSlice';

export const CloseIFrameModal = ({ onClose, redirect, closeIframe, question }) => {
  const dispatch = useDispatch();
  const [clearBasket] = useClearBasketMutation();
  const isDelivery = useMatch('/delivery');
  const handleRedirect = async () => {
    window.ReactNativeWebView?.postMessage(JSON.stringify({type:'CLEAR_BASKET'}));
    dispatch(resetSessionSearchData());
    await clearBasket();
    onClose();
    redirect();       
  };

  return (
    <div style={{
      width: '100%',
      textAlign: 'center',
      position: 'relative',
      padding: '40px 24px 40px'
    }}>
      <img src={question.src} style={{width: 120}}/>
      <h1
        className="title is-spaced is-uppercase"
        style={{ margin: '16px auto', lineHeight: '3rem', fontFamily: 'Domaine-display-condensed', fontSize: 32 }}
        data-testid='closeiframe-modal-heading'
      >
                Are you sure?
      </h1>
      <p style={{fontSize: 16}}>{`By closing this page, your basket will be held for 1 hour. ${ !isDelivery ? `Your collection slot may expire.` : `Your estimated delivery slot may change.` }`}</p>
      <button id='confirm' data-testid='closeiframe-modal-save' style={{ width: '190px', marginTop: '1.5rem' }} className='btn btn-primary' onClick={() => closeIframe(false)}>
                Save my order
      </button>
      <button id='confirm' data-testid='closeiframe-modal-start-over' style={{ width: '190px', marginTop: '16px' }} className='btn btn-secondary' onClick={handleRedirect}>
                Start over
      </button>
    </div>
  );
};