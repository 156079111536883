import React, { useEffect, useState } from 'react';
import Cross from '../shared/Cross';
import Tick from '../../img/menu/tickIcon.svg';
import ToggleOn from '../../img/menu/toggle-on.svg';
import ToggleOff from '../../img/menu/toggle-off.svg';
import unticked from '../../img/icons/unchecked.svg';
import GlutenFree from '../../img/icons/dietary/Gluten-Free.svg';
import Veg from '../../img/icons/dietary/Vegetarian.svg';
import Vegan from '../../img/icons/dietary/Vegan.svg';
import { updateFilters, setShowCalories } from '../../store/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from '../../store/storeTypes';

const RewardImage = (symbol) => {
  switch (symbol) {
    case 'GF':
      return GlutenFree;
    case 'V':
      return Veg;
    case 'VE':
      return Vegan;
  }
};

export const FilterButton = ({ onClick, selected, title, symbol, inApp }) => (
  <div className="filter-type-selector block mb-2"
    style={inApp ? { height: '4.25rem', display: 'flex', alignItems: 'center' } : {}}
  >
    <div
      className="is-flex is-align-content-center is-justify-content-space-between is-radius-medium is-clickable w100 dietary-card"
      onClick={onClick}
    >
      <div className="is-flex is-align-content-center">
        {
          selected ?
            <img
              className={'dietary-card-icon'}
              src={Tick}
              alt="Tick button"
            />
            :
            <img
              className={'dietary-card-icon'}
              src={unticked}
              alt="Tick button"
            />
        }
        <p className="diet-type-text my-a" style={{ paddingLeft: '1rem' }}>{title}</p>
      </div>
      <div className="is-flex is-align-content-center">
        <div className="icon is-medium">
          <img style={{ width: '32px', height: '32px' }} src={RewardImage(symbol)} />
        </div>
      </div>
    </div>
  </div>
);

export const FilterCaloriesButton = ({ onClick, selected }) => (
  <div className='filter-calories-container' onClick={onClick}>
    <div className='filter-calories-toggle'>
      <p>Show calories</p>
      {
        selected ?
          <div className='filter-calories-toggle-wrapper'>
            <img
              src={ToggleOn}
              alt="Toggle button"
            />
          </div>
          :
          <div className='filter-calories-toggle-wrapper'>
            <img
              src={ToggleOff}
              alt="Toggle button"
            />
          </div>
      }
    </div>
  </div>
);

const DietaryFilterModal = ({ onClose, inApp, screenWidth }) => {
  const filters = useSelector((state) => state.menu.filters);
  const showCalories = useSelector((state) => state.menu.showCalories);
  const [localFilters, setLocalFilters] = useState(filters);
  const [localShowCalories, setLocalShowCalories] = useState(showCalories);
  const dispatch = useDispatch();
  const isTabletScreen = useAppSelector(state => state.session.isTabletScreen);

  useEffect(() => {
    setLocalFilters(filters);
    setLocalShowCalories(showCalories);
  }, [filters, showCalories]);

  const handleFilterChange = (filter) => {
    setLocalFilters((currentFilters) =>
      currentFilters.includes(filter)
        ? currentFilters.filter((f) => f !== filter)
        : [...currentFilters, filter]
    );
  }

  const handleConfirm = () => {
    dispatch(updateFilters(localFilters));
    dispatch(setShowCalories(localShowCalories));
    // Timeout so any scrolling looks like it's done in the background
    setTimeout(() => onClose(), 50);
  };

  return (
    <div
      className={`filter-modal`}
    >
      {screenWidth > 550 ?
        <Cross onClick={handleConfirm} absolute rightPosition={12} topPosition={12} /> : <></>
      }
      <div className='diet-header-container'>
        <h3 className="title" style={inApp ? { textTransform: 'uppercase' } : {}}>FILTER BY DIET</h3>
        <div className="subtitle">
          <p>
            Click to filter the menu by dietary requirement and remember to let
            our team know when you order.
          </p>
        </div>
      </div>
      <div className='filter-btns-container'>
        <p className='diet-buttons-tip'>You can select multiple items</p>
        <FilterButton
          onClick={() => handleFilterChange('Gluten-Free')}
          selected={localFilters.includes('Gluten-Free')}
          title="Gluten-Free"
          symbol="GF"
          inApp={inApp}
        />
        <FilterButton
          onClick={() => handleFilterChange('Vegetarian')}
          selected={localFilters.includes('Vegetarian')}
          title="Vegetarian"
          symbol="V"
          inApp={inApp}
        />
        <FilterButton
          onClick={() => handleFilterChange('Vegan')}
          selected={localFilters.includes('Vegan')}
          title="Vegan"
          symbol="VE"
          inApp={inApp}
        />
        <FilterCaloriesButton
          onClick={() => setLocalShowCalories(!localShowCalories)}
          selected={localShowCalories}
        />
      </div>
      <div className='diet-btn-container'>
        <button
          id="confirm"
          className="btn btn-primary confirm-btn"
          onClick={handleConfirm}
        >
          Confirm
        </button>
        <div
          id="clearSelection"
          onClick={() => {
            dispatch(updateFilters([]));
            dispatch(setShowCalories(true));
            setLocalFilters([]);
            setLocalShowCalories(true);
          }}
          className="clear-btn-wrapper"
        >
          <p className="clear-btn">Clear selection</p>
        </div>
      </div>
    </div>
  );
};

export default DietaryFilterModal;
