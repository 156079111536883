import api from './api';
import { MenuState } from '../types/MenuTypes';

export const menuApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMenuState: builder.query<MenuState, string>({
      query: (restaurantId) => `menu/${restaurantId}`,
      providesTags: (menu) => menu
        ? ['MenuState']
        : []
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetMenuStateQuery,
  useLazyGetMenuStateQuery,
  usePrefetch
} = menuApi;