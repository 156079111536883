import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  copyMenuItemWithoutSubItems,
  copyMenuItemWithoutParent,
  addToppingToQuestion,
  addMenuItemToQuestion,
  removeToppingToQuestion,
  calcSubTotal,
  findQuestionOptions
} from '../../../helpers/basketHelpers';
import { customItemOptionsToGtmParams } from '../../../helpers/gtmEventHelpers';
import CustomiseCard from './CustomiseCard';
import chevronUp from '../../../img/icons/ChevronUpThin.svg'
import { RequiredTag } from '../../../components/RequiredTag';
import { gtmEvent } from '../../../helpers/commonHelpers';
import { useConfig } from '../../../helpers/useConfig';

const getNextSection = (result, item, isDuoRomana, duoRomanaIds) => {
  const newOptions = findQuestionOptions(result, item.name);
  const itemSelectedOptions = item.subProducts.filter(el => newOptions.find(option => el.name === option.name));

  if (isDuoRomana && !duoRomanaIds?.includes(item.parent.id)) return;

  const newNextItems = itemSelectedOptions.reduce((acc, cur) => acc.concat(cur.subProducts), []);

  return newNextItems
}

const CustomiseSection = ({
  item,
  NewComponent,
  result,
  setCustomisedObj,
  invalidSections,
  setInvalidSections,
  showErrors,
  resetItem,
  outOfStockItems,
  duoRomanaIds,
  isDuoRomana,
  handleRequiredScrolling,
  currentTier,
  showCalories,
  customId,
  selectedOptions,
  onSelectedOptionsChange,
  isPizza,
  quantity
}) => {
  const config = useConfig();
  const brand = config?.BRAND;
  const inApp = useSelector(state => state.session.inApp);
  const isDelivery = useSelector(state => state.basket.isDelivery);
  const basket = useSelector(state => state.basket);
  const [nextItems, setNextItems] = useState(getNextSection(result, item, isDuoRomana, duoRomanaIds));
  const [error, setError] = useState(false);
  const [showSectionErrors, setShowSectionErrors] = useState(false);
  const { filters } = useSelector(state => state.menu);
  const required = item.min === 1 && item.max <= item.min;
  const [isExpanded, setIsExpanded] = useState(true);
  const { isTabletScreen, customer } = useSelector(state => state.session);
  const baseSelections = useSelector(state => state.menu.baseSelections);
  const showSection = item && item.subProducts.length !== 1 && item.subProducts[0]?.name !== 'Standard';
  const itemLowerCaseName = item.name?.toLowerCase().trim();
  
  useEffect(() => {
    const invalid = invalidSections.find(el => el === itemLowerCaseName);
    if (invalid) {
      setError(true);
    } else {
      setError(false);
    }
  }, [invalidSections]);

  useEffect(() => {
    if (required && showSection) {
      setInvalidSections(sections => [...sections, itemLowerCaseName]); // Set all required and visible sections to invalid on startup
    }
  }, []);

  useEffect(() => {
    const newOptions = findQuestionOptions(result, item.name);
    const itemSelectedOptions = item.subProducts.filter(el => newOptions.find(option => el.name === option.name));
    if (itemSelectedOptions?.length > 0 && itemSelectedOptions[0]?.id === baseSelections.find(b => b.product === item.id)?.base || (customId && !newOptions[0]?.isPlaceholder)) {
      // Only select an option if the base dropdown has been populated or if it's a custom item (avoids any quick add selections)
      onSelectedOptionsChange(newOptions, itemLowerCaseName);
    }

    if (isDuoRomana && !duoRomanaIds?.includes(item.parent.id)) return;

    const newNextItems = itemSelectedOptions.reduce((acc, cur) => acc.concat(cur.subProducts), []);

    setNextItems(newNextItems);
  }, [result]);

  useEffect(() => {
    // console.log(itemLowerCaseName, !required, invalidSections.includes(itemLowerCaseName), selectedOptions[itemLowerCaseName]?.length !== 0, invalid)
    if (!required || invalidSections.includes(itemLowerCaseName) && selectedOptions[itemLowerCaseName]?.length !== 0) {
      setShowSectionErrors(true);
      setInvalidSections(sections => sections.filter(el => el !== itemLowerCaseName));  // Validate section when a selection has been made or if it is not a required section
    }
    if (required && !invalidSections.includes(itemLowerCaseName) && !selectedOptions[itemLowerCaseName]) {
      setInvalidSections(sections => [...sections, itemLowerCaseName]); // Set the section to invalid if it has no selections and is required (will happen when 'Reset Pizza' is clicked)
      setShowSectionErrors(false);
    }
  }, [selectedOptions[itemLowerCaseName]]);

  const findAndReplace = (resultObj, option, replaceMethod) => {
    const newValue = { ...resultObj };

    if (newValue.id === option.parent?.parent?.id) {
      let question = newValue.subProducts.find(p => p.id === option.parent.id);
      if (!question) {
        question = { ...option.parent, subProducts: [], calcSubTotal, quantity: 1 };
        newValue.subProducts.push(copyMenuItemWithoutParent(question));
      }
      replaceMethod(item, question, option);
      onSelectedOptionsChange(question.subProducts, itemLowerCaseName);
      setNextItems(option.subProducts.map(p => ({ ...p, parent: copyMenuItemWithoutSubItems(question) })));
    } else if (newValue.id === option.parent.id) {
      replaceMethod(item, newValue, option);
      onSelectedOptionsChange(newValue.subProducts.map(p => p.id), itemLowerCaseName);
      setNextItems(option.subProducts);
    } else {
      newValue.subProducts.map(product => findAndReplace(product, option, replaceMethod));
    }
    return newValue;
  };

  const handleCustomisation = (customisedObj, option) => {
    const event = {
      channel: inApp ? 'App' : 'Web',
      currency: brand === 'PE' ? 'GBP' : 'EUR',
      value: basket.total,
      clickAndCollectEvent: isDelivery
        ? 'Delivery_Product_Details_Clicked'
        : 'Collection_Product_Details_Clicked',
    };

    const item = {
      item_id: customisedObj.id,
      item_name: customisedObj.name,
      price: customisedObj.calcSubTotal(),
      quantity: quantity,
      item_brand: brand,
      item_category: customisedObj.categoryName
    };

    if (customer?.customerSessionToken?.pizzaExpressId) {
      event.customerId = customer.customerSessionToken.pizzaExpressId;
    }

    customItemOptionsToGtmParams(result, item);

    event['items'] = [item];

    gtmEvent('product_details_event', event);
    setCustomisedObj(customisedObj);
    if (required) handleRequiredScrolling(option.parent.name.toLowerCase().trim());
  };

  const handleSelectCard = (option) => {
    let customisedObj = findAndReplace(result, option, addMenuItemToQuestion);
    handleCustomisation(customisedObj, option);
  };

  const handleSelectTopping = (option) => {
    let customisedObj = findAndReplace(result, option, addToppingToQuestion);
    handleCustomisation(customisedObj, option);
  };

  const handleUnselectTopping = (option) => {
    let customisedObj = findAndReplace(result, option, removeToppingToQuestion);
    handleCustomisation(customisedObj, option);
  };

  const handleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      {
        item &&
        <>
          {
            showSection ? (
              <div className='customise-section' data-section-error-id={error ? itemLowerCaseName : null}>
                <div className='customise-section-title-container'>
                  <div className='customise-section-header-container'>
                    <h2 className="customise-section-header">{item.name}</h2>
                    {
                      required ?
                        <RequiredTag isErrored={error} showError={showErrors ? true : showSectionErrors} />
                        :
                        <img
                          src={chevronUp}
                          role='icon'
                          style={{ transform: !isExpanded ? 'rotate(180deg)' : '', cursor: 'pointer' }}
                          onClick={() => { handleAccordion() }}
                        />
                    }
                  </div>
                  {
                    required ?
                      <p className='customise-section-subheader'>Select one of the options below.</p>
                      :
                      <p className='customise-section-subheader'>Each additional topping is chargeable.</p>
                  }
                  {
                    !isTabletScreen && isExpanded && item.name?.toLowerCase()?.includes('toppings') &&
                    <a className='link dm-sans' style={{ fontSize: 18 }} onClick={resetItem}>Reset {isPizza ? 'Pizza' : 'Toppings'}</a>
                  }
                </div>
                {
                  isTabletScreen && item.name?.toLowerCase()?.includes('toppings') &&
                  <a className='link dm-sans customise-section-reset' style={{ fontSize: 18 }} onClick={resetItem}>Reset {isPizza ? 'Pizza' : 'Toppings'}</a>
                }
                {isExpanded &&
                  <div className='customise-card-option-container'>
                    {item.subProducts?.filter(option => !option.isIncluded)
                      .filter(el => {
                        if (!filters.length) return true;
                        if (selectedOptions[itemLowerCaseName]?.find(o => o.name === el.name)) return true; // If the subproduct is already selected, don't filter it out
                        if (!el.dietPreferences.length) return true; // If the subproduct has no diet preferences, assume it is a "product choice item" (e.g. "Choose your base" & "Why not add dough sticks")
                        if (filters.every(pref => el.dietPreferences.includes(pref))) return true;
                      })
                      .map((option, i) => {
                        const selected = selectedOptions[itemLowerCaseName]?.find(o => o.name.toLowerCase().trim() === option.name.toLowerCase().trim());
                        return (
                          <CustomiseCard
                            index={i}
                            name={option.name}
                            cost={option.cost}
                            parent={item}
                            option={option}
                            selected={selected}
                            cardSelect={() => handleSelectCard(option)}
                            handleIncrease={() => handleSelectTopping(option)}
                            handleDecrease={() => handleUnselectTopping(option)}
                            reachedMax={item.max <= selectedOptions[itemLowerCaseName]?.reduce((acc, option) => option.quantity + acc, 0)}
                            required={required}
                            currentTier={currentTier}
                            showCalories={showCalories}
                          />
                        );
                      })
                    }
                  </div>
                }
              </div>
            ) : null
          }
          {
            nextItems?.map((childQuestion, idx) =>
              <NewComponent
                key={idx}
                item={childQuestion}
                NewComponent={NewComponent}
                result={result}
                setCustomisedObj={setCustomisedObj}
                invalidSections={invalidSections}
                setInvalidSections={setInvalidSections}
                showErrors={showErrors}
                resetItem={resetItem}
                outOfStockItems={outOfStockItems}
                isDuoRomana={isDuoRomana}
                duoRomanaIds={duoRomanaIds}
                handleRequiredScrolling={handleRequiredScrolling}
                showCalories={showCalories}
                customId={customId}
                selectedOptions={selectedOptions}
                onSelectedOptionsChange={onSelectedOptionsChange}
                isPizza={isPizza}
                quantity={quantity}
              />
            )
          }
        </>
      }
    </>
  );
};

export default CustomiseSection;
