import { ApplicationResponse } from '../types/ApplicationTypes';
import api from './api';

export const applicationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getApplicationState: builder.query<ApplicationResponse, string | undefined>({
      query: (restaurantId) => restaurantId ? `application/${restaurantId}` : 'application',
      providesTags: (result) => result?.menu
        ? ['MenuState']
        : []
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetApplicationStateQuery,
  useLazyGetApplicationStateQuery,
} = applicationApi;