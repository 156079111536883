import { useEffect, useState } from 'react';
import getConfig, { AppConfig } from '../services/appConfig';

let cachedConfig = null as AppConfig | null;
export function useConfig() {
  const [config, setConfig] = useState(cachedConfig ?? null as AppConfig | null);
  useEffect(() => {
    const loadConfig = async () => {
      const newConfig = await getConfig();
      cachedConfig = newConfig;
      setConfig(newConfig);
    };
    if (!config) loadConfig();
  }, []);
  return config;
};